import { JSON_MIME_TYPE } from '../Constants';
import { KEY_SELECTED_EMPLOYER } from '../context/EmployerContext';
import { LOGIN_PAGE_ROUTE } from '../Routes';
import removeLoginKeyFromBrowserStorage from '../utils/removeLoginKeyFromBrowserStorage';

const apiRequest = async (url, options) => {
  const { headers } = options;
  const response = await fetch(url, {
    ...options,
    headers: {
      ...headers,
      accept: JSON_MIME_TYPE,
    },
  });
  if (response.ok) {
    if (response.status === 204) {
      return {};
    }
    return await response.json();
  } else {
    if (
      (response.status === 401 || response.status === 403) &&
      window.location.pathname !== LOGIN_PAGE_ROUTE
    ) {
      // NOTE - these statuses are seen when access tokens are invalid, e.g. if the user has let
      // their browser sit idle and their user access token times out and becomes invalid
      removeLoginKeyFromBrowserStorage();
      // also clear employer settings
      localStorage.removeItem(KEY_SELECTED_EMPLOYER);
      window.location.href = LOGIN_PAGE_ROUTE;
    }
    if (response.status === 404) {
      return { errorStatus: response.status || 'FAILED' };
    }

    return {
      errorStatus: response.status || 'FAILED',
      errorData: await response.json(),
    };
  }
};
export default apiRequest;
