import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import styles from './HubspotLeadGenerationBlock.module.scss';

const HubspotLeadGenerationBlock = ({ data, id }) => {
  const containerId = id ?? 'default';
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: data.portalid,
    formId: data.formid,
    target: `#${containerId}`,
  });
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      className={styles.root}
    >
      <Grid item>
        <section className={styles.wrapper}>
          <Typography variant='h3' className={styles.title}>
            {data.form_title && data.form_title}
          </Typography>
          <div id={containerId} className={styles['form-container']}></div>
          {error}
          {loaded}
          {formCreated}
        </section>
      </Grid>
    </Grid>
  );
};
export default HubspotLeadGenerationBlock;
