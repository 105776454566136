import makeBackendApiUrl from '../utils/makeBackendApiUrl';
import apiRequest from './apiRequest';

const getEmployer = async ({ userAccessToken, employerSlug }) => {
  const authHeader = userAccessToken ? { Authorization: `Token ${userAccessToken}` } : {};
  return apiRequest(makeBackendApiUrl(`/employers/${employerSlug}/`), {
    method: 'GET',
    headers: { ...authHeader },
  });
};
export default getEmployer;
