import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridImages: {
    marginTop: '2rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridRowGap: '1rem',
    gridColumnGap: '1rem',
    // the below will only apply to IE 9+
    '@media screen and (min-width:0\\0) and (min-resolution: +72dpi)': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gridImage: {
    marginTop: '2rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gridRowGap: '1rem',
    gridColumnGap: '1rem',
    // the below will only apply to IE 9+
    '@media screen and (min-width:0\\0) and (min-resolution: +72dpi)': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gridImageOuter: {
    borderRadius: '2px',
    backgroundColor: theme.palette.lightgray,
    position: 'relative',
    // aspect ratio 1:1
    '&:nth-child(1)': {
      gridRowStart: 1,
      gridRowEnd: 3,
      height: 0,
      paddingBottom: '100%',
      '&:last-of-type': {
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowEnd: 2,
        paddingBottom: '50%',
      },
      // the below will only apply to IE 9+
      '@media screen and (min-width:0\\0) and (min-resolution: +72dpi)': {
        display: 'none',
      },
    },
    '&:nth-child(2)': {
      gridColumnStart: 2,
      gridColumnEnd: 3,
      '&:last-of-type': {
        gridRowStart: 1,
        gridRowEnd: 3,
      },
      // the below will only apply to IE 9+
      '@media screen and (min-width:0\\0) and (min-resolution: +72dpi)': {
        display: 'none',
      },
    },
    '&:nth-child(3)': {
      // the below will only apply to IE 9+
      '@media screen and (min-width:0\\0) and (min-resolution: +72dpi)': {
        width: '100%',
        height: '100%',
        minHeight: '300px',
        maxHeight: '300px',
      },
    },
  },
  gridImageInner: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  gridImageButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 500,
    textDecoration: 'underline',
    background: 'linear-gradient(0deg, rgba(74, 79, 105, 0.9), rgba(74, 79, 105, 0.8))',
    outline: 'none',
    border: 'none',
    '&:focus': {
      border: `2px solid ${theme.palette.purple}`,
      background: 'linear-gradient(0deg, rgba(74, 79, 105, 1), rgba(74, 79, 105, 1))',
    },
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(74, 79, 105, 1), rgba(74, 79, 105, 1))',
    },
  },
  lightboxModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    '& .MuiDialog-container': {
      maxWidth: '600px',
      width: '100%',
      height: 'auto',
      maxHeight: 'calc(100% - 64px)',
      minHeight: '200px',
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-start',
      margin: 0,
      borderRadius: '8px',
      backgroundColor: 'white',
      border: '2px solid white',
      outline: 0,
      overflowY: 'scroll',
      '&:focus': {
        borderColor: theme.palette.purple,
      },
    },
    '& .MuiDialog-paper': {
      margin: 0,
      width: '100%',
      maxHeight: 'unset',
      // height: '100%',
      padding: '1rem',
      [theme.breakpoints.up('sm')]: {
        padding: '2rem 3rem',
      },
    },
    '& .image-gallery-slide': {
      minHeight: '150px',
      background: theme.palette.lightgray,
    },
    // custom lightbox btn focus states
    '& .image-gallery-icon': {
      '&:focus': {
        outlineColor: theme.palette.purple,
        backgroundColor: 'rgba(189, 141, 244, 0.3)',
      },
    },
  },
  lightboxClose: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    color: 'white',
    background: theme.palette.purple,
    border: 'none',
    zIndex: 1,
    '&:hover': {
      color: 'white',
      background: '#3f049e',
    },
  },
}));
