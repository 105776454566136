import { JSON_MIME_TYPE } from '../Constants';
import makeBackendApiUrl from '../utils/makeBackendApiUrl';
import apiRequest from './apiRequest';

const updateCandidateSavedJobs = async ({ userAccessToken, body }) => {
  return apiRequest(makeBackendApiUrl(`/candidate/job/save/`), {
    method: 'POST',
    headers: {
      'Content-Type': JSON_MIME_TYPE,
      Authorization: `Token ${userAccessToken}`,
    },
    body: JSON.stringify(body),
  });
};
export default updateCandidateSavedJobs;
