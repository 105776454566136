import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  linkContainer: {
    margin: 0,
    width: 'auto',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
  },

  bottomNavLinks: {
    minWidth: '240px',
    padding: '0 0 2rem 0 !important',
    [theme.breakpoints.up('md')]: {
      padding: '0 0 2rem 2rem !important',
    },
  },
  usefulLinks: {
    '& button': {
      textDecoration: 'underline',
    },
  },
  linkList: {
    listStyle: 'none',
  },
  linkHeader: {
    color: '#4A4F69',
    paddingBottom: '1.2rem',
    fontWeight: 'bold',
  },
  linkBody: {
    color: '#4A4F69',
    paddingBottom: '0.8rem',
  },
  linkAnchor: {
    padding: 0,
    justifyContent: 'flex-start',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.2rem',
    color: '#4A4F69',
    textAlign: 'left',
    textDecoration: 'none',
    outline: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
  },
}));
