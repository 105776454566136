import Typography from '@material-ui/core/Typography';
import React from 'react';

import SuccessEnablerIcon from '../SuccessEnablerIcon/SuccessEnablerIcon';
import useStyles from './JobMetadata.styles';

const JobMetadata = ({ job, showSuccessEnablers = true }) => {
  const classes = useStyles();

  const city = job.locality;
  const state = job.region;
  const successEnablers = job.accommodation_types;
  let successEnablersSection;
  if (showSuccessEnablers && successEnablers && successEnablers.length > 0) {
    successEnablersSection = (
      <ul className={classes.successEnablers}>
        {successEnablers.map((type) => (
          <li key={type}>
            <SuccessEnablerIcon iconName={type} />
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div>
      <div className={classes.toplineItems}>
        <Typography variant='body2' color='textSecondary' className={classes.toplineItem}>
          {city && `${city}, `}
          {state}
        </Typography>
      </div>
      {successEnablersSection}
    </div>
  );
};
export default JobMetadata;
