import makeStyles from '@material-ui/core/styles/makeStyles';
import merge from 'lodash.merge';

import { container } from '../../MixinsAndVars.styles';

export const useStyles = makeStyles((theme) => ({
  topNav: (props) => ({
    flexShrink: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 5,
    transition: '0.2s ease',
    backgroundColor: 'white',
    borderBottom: '1px solid #EBEBEC',
    '& .joinButton': {
      backgroundColor: theme.palette.purple,
      color: 'white',
      '&:hover': {
        color: theme.palette.black,
        backgroundColor: 'white',
        borderColor: theme.palette.black,
      },
      '&:focus': {
        color: theme.palette.purple,
        backgroundColor: 'white',
        borderColor: theme.palette.purple,
      },
    },
  }),
  contentContainer: merge(container(theme), {
    maxWidth: 'unset',
  }),
  content: {
    position: 'relative',
    height: '4.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0 0.75rem 0',
    height: 'auto',
    width: '140px',
    maxWidth: '140px',
    backgroundColor: 'transparent',
    border: 0,
    borderBottom: '4px solid transparent',
    cursor: 'pointer',
    outline: 0,
    '& img': {
      width: '100%',
    },
    '&:focus': {
      borderColor: theme.palette.purple,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
  mainLinks: {
    display: 'none',
    height: '76px',
    marginRight: '50px',
    alignItems: 'center',
    '&.shift-menu': {
      marginRight: 0,
      marginLeft: '60px',
    },
    '&.shift-left': {
      marginRight: 'auto',
      marginLeft: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      '&.scrolled-over': {
        display: 'none',
      },
    },
  },
  mainLink: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 6px',
    padding: '4px 8px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    opacity: 1,
    outline: 0,
    borderRadius: '8px',
    transition: '0.2s ease',
    '&.selected': {
      color: theme.palette.purple,
      backgroundColor: theme.palette.lightpurple2pt,
    },
    '&:focus': {
      '&::after': {
        content: '" "',
        display: 'block',
        margin: '0 0.5rem',
        width: 'calc(100% - 1rem)',
        position: 'absolute',
        bottom: '-4px',
        left: 0,
        height: '4px',
        backgroundColor: `${theme.palette.purple} !important`,
      },
    },
  },
  drawerButton: {
    display: 'none',
    padding: '6px',
    border: '2px solid transparent',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: theme.palette.lightgray,
    },
    '&:focus': {
      borderColor: theme.palette.purple,
    },
  },
  iconButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-0.5rem',
    '& .profile-button': {
      '& .MuiTouchRipple-root span': {
        opacity: 0.3,
        transform: 'scale(1.2)',
      },
    },
    '& .profile-image': {
      height: '32px',
      width: '32px',
      borderRadius: '50%',
    },
    '& .employer-logo': {
      height: 'auto',
      width: '30px',
      borderRadius: '4px',
    },
    '& .profile-name': {
      display: 'none',
      marginLeft: '0.2rem',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
  },
  accountsButtons: {
    flexShrink: 0,
    '& > *': {
      marginLeft: '0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  signInButton: {
    padding: '6px 1rem',
    minWidth: '4rem',
    color: theme.palette.black,
    fontSize: '0.875rem',
    fontWeight: 700,
    textDecoration: 'none',
    border: '2px solid transparent',
    borderRadius: '2.5rem',
    outline: 0,
    transition: '0.2s ease',
    '&:hover': {
      borderColor: theme.palette.black,
    },
    '&:focus': {
      borderColor: theme.palette.black,
    },
  },
  joinButton: {
    padding: '0.5rem 1.25rem',
    fontSize: '0.875rem',
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.black,
    borderRadius: '2.5rem',
    border: '2px solid transparent',
    backgroundColor: 'white',
    outline: 0,
    transition: '0.2s ease',
    '&:hover': {
      borderColor: theme.palette.black,
    },
    '&:focus': {
      borderColor: theme.palette.purple,
    },
  },
  joinMobile: {
    display: 'none',
    marginRight: '0.5rem',
    padding: '0.5rem 1.25rem',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: 'white',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    backgroundColor: theme.palette.purple,
    borderRadius: '2.5rem',
    border: `2px solid ${theme.palette.purple}`,
    outline: 0,
    transition: '0.2s ease',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&:hover': {
      color: theme.palette.black,
      backgroundColor: 'transparent',
      borderColor: theme.palette.black,
    },
    '&:focus': {
      color: theme.palette.black,
      backgroundColor: 'transparent',
      borderColor: theme.palette.black,
    },
  },
  productMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  profileProgressContainer: {
    display: 'none',
    marginRight: '8px',
    '@media (min-width:1100px)': {
      display: 'flex',
    },
  },
  profileProgressButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    fontSize: '0.875rem',
    lineHeight: 1,
    fontWeight: 500,
    color: theme.palette.black,
    textDecoration: 'underline',
    borderRadius: '28px',
    border: `2px solid transparent`,
    transition: '0.2s ease',
    outline: 0,
    '&:focus': {
      borderColor: theme.palette.purple,
    },
    '&:hover': {
      backgroundColor: theme.palette.lightgray,
    },
  },
  profileProgressPie: {
    width: '18px',
    height: '18px',
    marginRight: '8px',
  },
  profileProgressDismiss: {
    height: '18px',
    width: '18px',
    padding: '6px',
    marginLeft: '-10px',
    color: theme.palette.black,
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.black}`,
    '& svg': {
      height: '12px',
      width: '12px',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:focus': {
      backgroundColor: theme.palette.purple,
      borderColor: theme.palette.purple,
      color: 'white',
    },
  },
  multiselect: {
    minHeight: '51px',
    '& .MuiAutocomplete-inputRoot': {
      minHeight: '51px',
      '@media (min-width:1070px)': {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 1,
      },
      '& .MuiAutocomplete-input': {
        minWidth: '5px',
      },
    },
    '& .MuiChip-root': {
      maxWidth: 'calc(100% - 40px)',
      fontSize: '0.7rem',
      backgroundColor: theme.palette.lightgray,
      '&:focus': {
        border: `1px solid ${theme.palette.purple}`,
      },
      '& + span.MuiAutocomplete-tag': {
        margin: '1px',
        fontSize: '0.85rem',
      },
    },
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      zIndex: 2,
      transform: 'translate(14px, 20px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));
