import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import useCmsCollectionQuery from '../../hooks/useCmsCollectionQuery';
import useCmsPageQuery from '../../hooks/useCmsPageQuery';
import useQueryParams from '../../hooks/useQueryParams';
import { AlertBanner } from '../AlertBanner/AlertBanner';
import HubspotLeadGenerationBlock from '../marketing/HubspotLeadGenerationBlock';
import PageErrorBoundary from '../PageErrorBoundary/PageErrorBoundary';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';
import PageLoadingIndicator from '../PageLoadingIndicator/PageLoadingIndicator';

const CorePageWrapper = (props) => {
  const routeLocation = useLocation();
  const params = useQueryParams();
  const { pageSlug: paramPageSlug } = useParams();
  const preview = params.get('preview');
  const { page, pageId, cmsPageType } = props;
  // banner-specific state
  const [bannerHeight, setBannerHeight] = useState(0);
  const [bannerOnDisplay, setBannerOnDisplay] = React.useState(
    sessionStorage.getItem('bannerOnDisplayNDEAM') || 'display'
  );

  let pageSlug = pageId;
  if (!pageSlug) {
    pageSlug = paramPageSlug;
  }
  if (!pageSlug) {
    pageSlug = routeLocation.pathname.substring(1);
    if (pageSlug.endsWith('/')) {
      pageSlug = pageSlug.substring(0, pageSlug.length - 1);
    }
  }

  const utmSource = params.get('utm_source') || '';
  const utmMedium = params.get('utm_medium') || '';
  const utmCampaign = params.get('utm_campaign') || '';
  if (utmSource || utmMedium || utmCampaign) {
    Cookies.set('utm_source', utmSource, {
      domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
      expires: 30,
    });
    Cookies.set('utm_medium', utmMedium, {
      domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
      expires: 30,
    });
    Cookies.set('utm_campaign', utmCampaign, {
      domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
      expires: 20,
    });
  }

  useEffect(() => {
    const cookieBanner = document.getElementById('CybotCookiebotDialog');
    if (cookieBanner) {
      const cookieImg = cookieBanner.querySelector('#CybotCookiebotDialogPoweredbyImage');
      if (cookieImg) {
        cookieImg.setAttribute('alt', '');
        cookieImg.setAttribute('aria-hidden', 'true');
      }
    }
  }, []);

  const { data: bannerData, status: bannerDataStatus } = useCmsCollectionQuery('banner');
  const { data: pageCmsData, status: pageCmsDataStatus } = useCmsPageQuery(
    pageSlug,
    preview,
    cmsPageType
  );
  const { data: footerCmsData, status: footerCmsDataStatus } = useCmsCollectionQuery('footer');
  const { data: socialMediaData, status: socialMediaDataStatus } =
    useCmsCollectionQuery('social_media');
  const { data: headerCmsData, status: headerCmsDataStatus } = useCmsCollectionQuery(
    'ligilo_header_and_navigation'
  );

  const cmsDataIsLoading =
    pageCmsDataStatus === 'loading' ||
    headerCmsDataStatus === 'loading' ||
    bannerDataStatus === 'loading' ||
    socialMediaDataStatus === 'loading' ||
    footerCmsDataStatus === 'loading';

  useEffect(() => {
    sessionStorage.setItem('bannerOnDisplayNDEAM', bannerOnDisplay);
  }, [bannerOnDisplay]);

  useEffect(() => {
    if (pageCmsDataStatus === 'success') {
      if (pageCmsData.data && pageCmsData.data.fields && pageCmsData.data.fields.page_metadata) {
        const metadata = pageCmsData.data.fields.page_metadata;
        if (metadata.registration_source_category) {
          const sourceData = {
            category: metadata.registration_source_category,
            name: metadata.registration_source_name || '',
          };
          window.localStorage.setItem('source', JSON.stringify(sourceData));
        }
      }
    }
  }, [pageCmsData, pageCmsDataStatus]);

  const handleBannerDisplay = () => {
    setBannerHeight(0);
    setBannerOnDisplay('dismissed');
  };

  let headerProps = {
    elevation: 0,
    hideNavigation: true,
  };

  if (cmsDataIsLoading) {
    return (
      <>
        <PageLoadingIndicator />
      </>
    );
  } else {
    const pageData = pageCmsData && pageCmsData.data;
    const hasHubspotForm = pageCmsData?.data?.fields?.hubspot_lead_generation ? true : false;
    return (
      <>
        <Helmet>
          {pageData && pageData.name ? (
            <title>
              {pageData.slug === 'home' ? `${pageData.name}` : `${pageData.name} - Inclusively`}
            </title>
          ) : (
            <title>Inclusively</title>
          )}
          {pageData &&
            pageData.fields &&
            pageData.fields.page_metadata &&
            pageData.fields.page_metadata.meta_description && (
              <meta name='description' content={pageData.fields.page_metadata.meta_description} />
            )}
          {pageData &&
            pageData.fields &&
            pageData.fields.page_metadata &&
            pageData.fields.page_metadata.meta_image && (
              <meta name='og:image' content={pageData.fields.page_metadata.meta_image} />
            )}
        </Helmet>
        <a href='#maincontent' id='skip-nav' className='skip-link'>
          Skip to main content
        </a>
        {/* user check + URL check as needed for banner display */}
        {bannerData.data.banner.length > 0 &&
        !window.location.pathname.includes('onboarding') &&
        !window.location.pathname.includes('accounts') ? (
          <AlertBanner
            bannerOnDisplay={bannerOnDisplay}
            updateDisplay={handleBannerDisplay}
            cmsData={bannerData.data.banner[0]}
          />
        ) : (
          <div className='js-alert-banner' style={{ height: 0 }}></div>
        )}
        <PageHeader
          {...headerProps}
          cmsData={headerCmsData.data.ligilo_header_and_navigation[0]}
          bannerHeight={bannerHeight}
        />
        <PageErrorBoundary>
          <div role='main' id='maincontent' style={{ marginTop: bannerHeight + 76 + 'px' }}>
            {React.createElement(page, {
              ...props,
              cmsData: pageCmsData && pageCmsData.data && pageCmsData.data.fields,
            })}
            {hasHubspotForm && (
              <HubspotLeadGenerationBlock data={pageCmsData.data.fields.hubspot_lead_generation} />
            )}
          </div>
          <PageFooter
            cmsData={footerCmsData.data.footer}
            socialMedia={socialMediaData.data.social_media}
          />
        </PageErrorBoundary>
      </>
    );
  }
};
export default CorePageWrapper;
