import { useContext } from 'react';
import { useQuery } from 'react-query';

import getCmsPageData from '../api/getCmsPageData';
import { LocaleContext } from '../context/LocaleContext';

const queryFn = async (key, pageType, contentId, locale, preview) =>
  getCmsPageData({ pageType, contentId, locale, preview });

const useCmsPageQuery = (contentId, preview, pageType) => {
  const {
    state: { locale },
  } = useContext(LocaleContext);
  const readyToMakeQuery = contentId;
  return useQuery(readyToMakeQuery && ['cms-page', pageType, contentId, locale, preview], queryFn);
};
export default useCmsPageQuery;
