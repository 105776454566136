import makeStyles from '@material-ui/core/styles/makeStyles';
import merge from 'lodash.merge';

import { container } from '../../MixinsAndVars.styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexShrink: 0,
    flexWrap: 'nowrap',
    position: 'relative',
    background: '#f2f4fa',
    padding: '3rem 0 0 0',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '7.5rem 0 0 0',
      textAlign: 'left',
    },
  },
  contentContainer: merge(container(theme), {
    direction: 'row',
    justifyContent: 'space-between',
    margin: '1.5rem auto',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  }),
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
  logoContainer: {
    display: 'flex',
  },
  logo: {
    direction: 'row',
    justifyContent: 'flex-start',
    width: '9.375rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
      flex: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  footerLinkContainer: {
    paddingBottom: '1rem',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '6rem',
      marginLeft: 'auto',
    },
    '& > div': {
      width: '100%',
    },
  },
  footerBottomRow: {
    borderTop: `2px solid ${theme.palette.lightgray2}`,
    '& > div': {
      width: '100%',
      marginTop: '2rem',
    },
  },
  copyright: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
    color: '#4A4F69',
  },
  socialLink: {
    display: 'flex',
    outline: 0,
    '&:hover img': {
      border: `1px solid ${theme.palette.gray}`,
    },
    '&:focus img': {
      backgroundColor: theme.palette.lightpurple4pt,
      border: `1px solid ${theme.palette.gray}`,
    },
  },
  socialIcon: {
    height: '2.5rem',
    width: '2.5rem',
    background: 'white',
    padding: '11px',
    borderRadius: '8px',
  },
  socialIconContainer: {
    flexWrap: 'noWrap',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  newsletter: {
    color: '#4A4F69',
  },
}));
