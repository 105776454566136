import { Grid } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

import LogoColor from '../../assets/logo/logo-color.svg';
import {
  ABOUT_US_PAGE_ROUTE,
  FAQ_PAGE_ROUTE,
  LOGIN_PAGE_URL,
  REGISTER_PAGE_ROUTE,
} from '../../Routes';
import CorePageMenu from './CorePageMenu';
import { useStyles } from './PageHeader.styles';

const PageHeader = (props) => {
  const classes = useStyles(props);
  let routeLocation = useLocation();
  routeLocation = routeLocation.pathname;

  const navData = [
    {
      route: ABOUT_US_PAGE_ROUTE,
      label: 'About',
    },
    {
      route: FAQ_PAGE_ROUTE,
      label: 'FAQs',
    },
  ];

  const joinNowClicked = () => {
    window.gtag('event', 'conversion', { send_to: 'AW-613619401/cttuCOvhqNkBEMmtzKQC' });
  };

  return (
    <nav
      className={classes.topNav}
      aria-label='main'
      // style={{ top: bannerHeight + 'px' }}
    >
      <div className={classes.contentContainer} data-cy='page-header-container'>
        <Grid
          className={classes.content}
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          wrap='nowrap'
        >
          <a
            data-cy='page-header-logo'
            className={classes.logoImage}
            href={`https://www.${process.env.REACT_APP_COOKIE_DOMAIN}`}
          >
            <img src={LogoColor} alt='Inclusively homepage' />
          </a>
          <div className={`${classes.mainLinks} shift-menu js-nav-links`}>
            <CorePageMenu />
            {navData.map(({ route, label }) => (
              <a
                key={route}
                className={`${classes.mainLink}${routeLocation === route ? ' selected' : ''}`}
                href={route}
                aria-current={routeLocation === route ? 'page' : ''}
              >
                {label}
              </a>
            ))}
          </div>
          <div className={classes.iconButtons}>
            <div className={classes.accountsButtons}>
              <a href={LOGIN_PAGE_URL} className={classes.signInButton}>
                Sign In
              </a>
              <a
                href={REGISTER_PAGE_ROUTE}
                data-cy='page-header-join-now'
                className={`${classes.joinButton} joinButton`}
                onClick={joinNowClicked}
              >
                Sign Up
              </a>
            </div>
            <a
              href={REGISTER_PAGE_ROUTE}
              data-cy='page-header-join-now'
              className={classes.joinMobile}
              onClick={joinNowClicked}
            >
              Sign Up
            </a>
          </div>
        </Grid>
      </div>
    </nav>
  );
};
export default PageHeader;
