import React, { createContext, useReducer } from 'react';
import { useQuery } from 'react-query';

import getUser from '../api/getUser';
import { LOGIN_KEY_FOR_BROWSER_STORAGE, REACT_APP_COOKIE_DOMAIN } from '../Constants';
import removeLoginKeyFromBrowserStorage from '../utils/removeLoginKeyFromBrowserStorage';

const initialState = {
  loginDetails: (() => {
    try {
      const localDetails = localStorage.getItem(LOGIN_KEY_FOR_BROWSER_STORAGE);
      const sessionDetails = sessionStorage.getItem(LOGIN_KEY_FOR_BROWSER_STORAGE);
      if (localDetails) {
        return JSON.parse(localDetails);
      } else if (sessionDetails) {
        return JSON.parse(sessionDetails);
      }
    } catch (error) {
      removeLoginKeyFromBrowserStorage();
    }
  })(),
  checkedForValidAccessTokenOnStartup: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_REMEMBERED_LOGIN_DETAILS':
      removeLoginKeyFromBrowserStorage();
      try {
        localStorage.setItem(LOGIN_KEY_FOR_BROWSER_STORAGE, JSON.stringify(action.payload));
        document.cookie = `token=${action.payload.token}; domain=${REACT_APP_COOKIE_DOMAIN}; path=/`;
      } catch (error) {
        // don't do anything, nothing we can do if the stringify call fails
      }
      return {
        ...state,
        loginDetails: action.payload,
      };
    case 'SET_LOGIN_DETAILS':
      removeLoginKeyFromBrowserStorage();
      try {
        sessionStorage.setItem(LOGIN_KEY_FOR_BROWSER_STORAGE, JSON.stringify(action.payload));
        document.cookie = `token=${action.payload.token}; domain=${REACT_APP_COOKIE_DOMAIN}; path=/`;
      } catch (error) {
        // don't do anything, nothing we can do if the stringify call fails
      }
      return {
        ...state,
        loginDetails: action.payload,
      };
    case 'INVALID_LOGIN_DETAILS_ON_STARTUP':
      removeLoginKeyFromBrowserStorage();
      return {
        ...state,
        loginDetails: null,
        checkedForValidAccessTokenOnStartup: true,
      };
    case 'VALID_OR_NO_LOGIN_DETAILS_ON_STARTUP':
      return {
        ...state,
        checkedForValidAccessTokenOnStartup: true,
      };
    case 'LOGOUT':
      removeLoginKeyFromBrowserStorage();
      document.cookie = `token=; domain=${REACT_APP_COOKIE_DOMAIN};  expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      return {
        ...state,
        loginDetails: null,
      };
    default:
      throw new Error();
  }
};

export const LoginDetailsContext = createContext(initialState);

export const LoginDetailsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loginDetails: initialLoginDetails } = initialState;
  const queryFn = async (key, userAccessToken) => getUser({ userAccessToken });
  const readyToMakeQuery = initialLoginDetails && initialLoginDetails.token;
  const { status, data } = useQuery(
    readyToMakeQuery && ['user', initialLoginDetails.token],
    queryFn
  );
  if (status === 'success' && !state.checkedForValidAccessTokenOnStartup) {
    dispatch({
      type:
        data && data.errorStatus
          ? 'INVALID_LOGIN_DETAILS_ON_STARTUP'
          : 'VALID_OR_NO_LOGIN_DETAILS_ON_STARTUP',
    });
  }
  return (
    <LoginDetailsContext.Provider value={{ state, dispatch }}>
      {state.checkedForValidAccessTokenOnStartup && children}
    </LoginDetailsContext.Provider>
  );
};
