import makeStyles from '@material-ui/core/styles/makeStyles';
import merge from 'lodash.merge';

import { container } from '../../MixinsAndVars.styles';

export default makeStyles((theme) => ({
  contentContainer: {
    position: 'fixed',
    left: 0,
    right: 0,
    width: '100%',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '14px',
    paddingBottom: '14px',
    textAlign: 'center',
    backgroundColor: theme.palette.lightpurple,
    zIndex: 6,
    '&.hide': {
      display: 'none',
    },
  },
  contentContainerInner: merge(container(theme), {
    position: 'relative',
    paddingRight: '3.5rem !important',
  }),
  headline: {
    display: 'inline',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: 'black',
  },
  bannerCopy: {
    display: 'inline',
    color: 'black',
  },
  learnMoreLink: {
    display: 'inline',
    textDecoration: 'underline',
    color: theme.palette.purple,
  },
  close: {
    position: 'absolute',
    top: '-12px',
    right: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      right: '1rem',
    },
  },
}));
