import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import React, { useRef } from 'react';

import { ReactComponent as PremierIcon } from '../../assets/svg-icons/premier.svg';
import useUpdateCandidateSavedJobsMutation from '../../hooks/useUpdateCandidateSavedJobsMutation';
import { makeJobRoute } from '../../Routes';
import JobBriefing from '../JobBriefing/JobBriefing';
import useStyles from './JobCard.styles';

// TODO (JOB APP STATUS): pull in job application statuses when its ready
// const availableStatuses = ['Connected', 'In Progress', 'Completed'];
const availableStatuses = [];

const JobCard = ({
  job,
  profile,
  shadow,
  sidebar,
  splitLayout,
  handleSidebarSelection,
  selectedJob,
  updateAppStatus,
  advocateCandidate,
}) => {
  const classes = useStyles();
  const tooltipRef = useRef();
  const jobId = job.public_id;
  const isPremier = job.employer.is_premier;
  const isSaved = profile && profile.saved.find((item) => item.public_id === jobId);
  const [updateSavedJobs, { status: updateSavedJobsStatus }] =
    useUpdateCandidateSavedJobsMutation();
  const requestInProgress = updateSavedJobsStatus === 'loading';

  const onSaveClicked = async () => {
    if (requestInProgress) {
      return null;
    }
    await updateSavedJobs({
      body: {
        action: isSaved ? 'R' : 'A',
        public_id: jobId,
      },
    });
  };

  // ROUTING CONDITIONALS: if this card is pulling into a sidebar layout AND if the screen width is above 960px,
  // button is used to selected job id on the split screen layout
  // OTHERWISE: a normal <a> link is used to a job detail page

  return (
    <article className={classes.root}>
      {sidebar && splitLayout ? (
        <button
          type='button'
          className={`${classes.wrappingButton}${selectedJob === jobId ? ' selected' : ''}`}
          onClick={() => handleSidebarSelection(jobId)}
        >
          <JobBriefing job={job} />
        </button>
      ) : (
        <a
          className={`${classes.wrappingButton}${shadow ? ' shadow' : ''}`}
          href={makeJobRoute(jobId)}
          aria-label={`${job.title} full details`}
        >
          <JobBriefing job={job} />
        </a>
      )}
      {isPremier && !updateAppStatus && (
        <div className={classes.premierWrapper}>
          <button
            type='button'
            className='premier-tooltip-button'
            aria-labelledby={`premier-tooltip-${job.public_id}`}
            // provides way for user to dismiss tooltip (with ESC) without moving focus
            onKeyUp={(event) => {
              if (event.charCode === 27 || event.key === 'Escape') {
                if (tooltipRef && tooltipRef.current) {
                  tooltipRef.current.classList.add('dismissed');
                }
              }
            }}
            // ensure user can resurface tooltip once they've dismissed and navigated away
            onBlur={() => {
              if (tooltipRef && tooltipRef.current) {
                tooltipRef.current.classList.remove('dismissed');
              }
            }}
          >
            {React.createElement(PremierIcon)}
            <span
              role='tooltip'
              className='premier-tooltip'
              ref={tooltipRef}
              id={`premier-tooltip-${job.public_id}`}
            >
              As a <span className='premier-highlight'>Premier Employer</span>, this company is
              proud to work closely with Inclusively to match its job accommodations with
              candidates' success enablers.
            </span>
          </button>
        </div>
      )}
      {profile && !updateAppStatus && !advocateCandidate && (
        <div className={classes.saveJobButtonContainer}>
          {requestInProgress && <CircularProgress color='secondary' size={14} />}
          <IconButton
            className={classes.saveJobButton}
            onClick={onSaveClicked}
            disabled={requestInProgress}
            aria-label='Save job'
            aria-pressed={isSaved ? true : false}
          >
            {isSaved ? (
              <BookmarkIcon
                fontSize='inherit'
                color={requestInProgress ? 'disabled' : 'secondary'}
              />
            ) : (
              <BookmarkBorderOutlinedIcon
                fontSize='inherit'
                color={requestInProgress ? 'disabled' : 'inherit'}
              />
            )}
          </IconButton>
        </div>
      )}
      {profile && updateAppStatus && availableStatuses.length > 0 && (
        <div className={classes.updateStatusButtonContainer}>
          <label>
            <span className={classes.srOnly}>Application Status</span>
            <select name='Application Status' className={classes.updateStatusButton}>
              {availableStatuses.map((status, idx) => (
                <option key={idx} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}
    </article>
  );
};
export default JobCard;
