import makeStyles from '@material-ui/core/styles/makeStyles';

import Arrow from '../../assets/svg-icons/arrow-caret.svg';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  updateStatusButtonContainer: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  updateStatusButton: {
    padding: '2px 24px 2px 8px',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    outline: 0,
    border: '1px solid transparent',
    borderRadius: '25px',
    backgroundColor: theme.palette.lightpurple4pt,
    appearance: 'none', // replace default select arrow
    background: `url(${Arrow}) no-repeat ${theme.palette.lightgray}`,
    backgroundSize: '7px 4px',
    backgroundPosition: '90% 50%',
    transition: '0.2s ease',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.black,
      boxShadow: '0px 2px 8px rgba(34, 34, 59, 0.08)',
    },
    '&:focus': {
      borderColor: theme.palette.purple,
    },
  },
  saveJobButtonContainer: {
    position: 'absolute',
    top: 8,
    right: 8,
    fontWeight: 400,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: '0.2rem',
    },
  },
  wrappingButton: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    textDecoration: 'none',
    color: 'inherit',
    border: `1px solid ${theme.palette.lightgray2}`,
    borderRadius: '4px',
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: '0.2s ease',
    outline: 0,
    '&.shadow': {
      filter: 'drop-shadow(0px 2px 16px #EEEEF5)',
    },
    '&.selected': {
      borderColor: theme.palette.purple,
    },
    '&:hover': {
      borderColor: theme.palette.gray,
    },
    '&:focus': {
      borderColor: theme.palette.purple,
      borderWidth: '2px',
    },
  },
  premierWrapper: {
    position: 'absolute',
    top: '8px',
    right: '46px',
    zIndex: 1,
    '& .premier-highlight': {
      color: theme.palette.purple,
      fontWeight: 500,
    },
    '& .premier-tooltip-button': {
      width: '3rem',
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      outline: 0,
      border: '2px solid transparent',
      background: 'transparent',
      cursor: 'pointer',
      borderRadius: '50%',
      transition: '0.2s ease',
      '& svg': {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
    '& .premier-tooltip': {
      display: 'none',
      position: 'absolute',
      top: '50px',
      right: '-40px',
      width: '260px',
      padding: '0.75rem',
      textAlign: 'left',
      background: 'white',
      border: `2px solid ${theme.palette.purple}`,
      borderRadius: '4px',
      boxShadow: '0px 2px 8px rgba(34, 34, 59, 0.08)',
    },
    '& .premier-tooltip-button:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& .premier-tooltip': {
        display: 'block',
      },
    },
    '& .premier-tooltip-button:focus': {
      borderColor: theme.palette.purple,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& .premier-tooltip': {
        display: 'block',
        '&.dismissed': {
          display: 'none',
        },
      },
    },
  },
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
}));
