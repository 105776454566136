import makeStyles from '@material-ui/core/styles/makeStyles';
import merge from 'lodash.merge';

import { container } from '../../MixinsAndVars.styles';

export default makeStyles((theme) => ({
  root: merge(container(theme), {
    flexGrow: 1,
    padding: '4rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  indicatorContainer: {
    width: '70vw',
  },
}));
