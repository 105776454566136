import React, { Fragment } from 'react';

const LineBreaker = ({ content }) => {
  return (
    <>
      {content.split('\n').map((value, index) => (
        <Fragment key={index}>
          {value}
          <br />
        </Fragment>
      ))}
    </>
  );
};
export default LineBreaker;
