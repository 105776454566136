import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

import RouteLink from '../RouteLink/RouteLink';
import useStyles from './AlertBanner.styles';

function AlertBanner({ cmsData, bannerOnDisplay, updateDisplay }) {
  const classes = useStyles();

  const handleBannerClose = () => {
    updateDisplay(false);
  };

  return (
    <aside
      className={`js-alert-banner ${bannerOnDisplay !== 'display' ? 'hide ' : ''}${
        classes.contentContainer
      }`}
      aria-hidden={bannerOnDisplay !== 'display'}
      aria-live='polite'
    >
      <div className={classes.contentContainerInner}>
        {cmsData.banner_headline && (
          <Typography variant='body2' component='p' className={classes.headline}>
            {cmsData.banner_headline}
            {' – '}
          </Typography>
        )}
        {cmsData.banner_subheadline && (
          <Typography className={classes.bannerCopy} variant='body2' color='textSecondary'>
            {cmsData.banner_subheadline}{' '}
          </Typography>
        )}
        {cmsData.banner_hyperlink_text && cmsData.banner_hyperlink_url && (
          <RouteLink
            label={cmsData.banner_hyperlink_text}
            ariaLabel={
              cmsData.banner_hyperlink_aria_label ? cmsData.banner_hyperlink_aria_label : null
            }
            route={cmsData.banner_hyperlink_url}
            className={classes.learnMoreLink}
            // additional logic to dismiss banner after link is clicked
            handleBannerClose={handleBannerClose}
          />
        )}
        <IconButton aria-label='close banner' onClick={handleBannerClose} className={classes.close}>
          <CloseIcon />
        </IconButton>
      </div>
    </aside>
  );
}

AlertBanner.propTypes = {
  bannerOnDisplay: PropTypes.string,
  updateDisplay: PropTypes.func,
  cmsData: PropTypes.shape({
    banner_headline: PropTypes.string.isRequired,
    banner_hyperlink_aria_label: PropTypes.string.isRequired,
    banner_hyperlink_text: PropTypes.string.isRequired,
    banner_hyperlink_url: PropTypes.string.isRequired,
    banner_subheadline: PropTypes.string.isRequired,
    banner_subheadline_html: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export { AlertBanner };
