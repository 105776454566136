import React, { lazy, Suspense } from 'react';
import CookieBot from 'react-cookiebot';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import GoogleAnalyticsPageViewController from './components/GoogleAnalyticsPageViewController/GoogleAnalyticsPageViewController';
import CorePageWrapper from './components/PageWrapper/CorePageWrapper';
import ScrollToPageTopController from './components/ScrollToPageTopController/ScrollToPageTopController';
import { COOKIEBOT_DOMAIN_GROUP_ID } from './Constants';
import {
  ABLE_TODAY_ADA_WEBINAR_PAGE_ROUTE,
  ABOUT_US_PAGE_ROUTE,
  ACCESSIBILITY_STATEMENT_PAGE_ROUTE,
  ADVOCATE_PORTAL_RESOURCE_PAGE_ROUTE,
  CANDIDATE_DEMO_PAGE_ROUTE,
  COMMUNITY_GUIDELINES_ROUTE,
  COMPANY_DIRECTORY_PAGE_ROUTE,
  COOKIES_PAGE_ROUTE,
  COVID_19_RESPONSE_PAGE_ROUTE,
  DEIA_PAGE_ROUTE,
  EMPLOYER_DISPLAY_PAGE_ROUTE,
  FAQ_PAGE_ROUTE,
  FIND_MEANINGFUL_EMPLOYMENT_FOR_THE_JOB_SEEKERS_YOU_SUPPORT,
  FOR_ADVOCATES_PAGE_ROUTE,
  FOR_CANDIDATES_PAGE_ROUTE,
  FOR_EMPLOYERS_PAGE_ROUTE,
  JOB_PAGE_ROUTE,
  NEWS_AND_RESOURCES_DETAIL_PAGE_ROUTE,
  NEWS_AND_RESOURCES_PARENT_ROUTE,
  NEXT_GEN_WORKFORCE_PAGE_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  OCTOBER_PANEL_PAGE_ROUTE,
  ON_DEMAND_DEMO_PAGE_ROUTE,
  PARTNER_DETAIL_PAGE_ROUTE,
  PARTNER_PLUS_PAGE_ROUTE,
  PARTNERSHIP_DIRECTORY_PAGE_ROUTE,
  PARTNERSHIPS_PAGE_ROUTE,
  PARTNERSHIPS_UNI_NONPROFITS_PAGE_ROUTE,
  PRIVACY_PAGE_ROUTE,
  REGISTER_PAGE_ROUTE,
  ROUNDTABLE_ROUTES,
  SITEMAP_PAGE_ROUTE,
  TERMS_AND_CONDITIONS_PAGE_ROUTE,
} from './Routes';
import { redirectExternal } from './utils/redirectExternal';
import EmployerDisplayPage from './views/EmployerDisplayPage/EmployerDisplayPage';

const AdvocatePortalResourcePage = lazy(() => import('./views/ForAdvocatesPage/ForAdvocatesPage'));
const AboutUsPage = lazy(() => import('./views/AboutUsPage/AboutUsPage'));
const FAQsPage = lazy(() => import('./views/FAQsPage/FAQsPage'));
const CompanyDirectoryPage = lazy(() => import('./views/CompanyDirectory/CompanyDirectory'));
const Covid19Page = lazy(() => import('./views/Covid19Page/Covid19Page'));
const ForAdvocatesPage = lazy(() => import('./views/ForAdvocatesPage/ForAdvocatesPage'));
const ForCandidatesPage = lazy(() => import('./views/ForCandidatesPage/ForCandidatesPage'));
const ForEmployersPage = lazy(() => import('./views/ForEmployersPage/ForEmployersPage'));
const JobPage = lazy(() => import('./views/JobPage/JobPage'));
const NewsAndResourcesPage = lazy(() =>
  import('./views/NewsAndResourcesPage/NewsAndResourcesPage')
);
const NewsAndResourcesDetailPage = lazy(() =>
  import('./views/NewsAndResourcesPage/NewsDetailPage')
);
const NotFoundPage = lazy(() => import('./views/NotFoundPage/NotFoundPage'));
const PartnerDetailPage = lazy(() => import('./views/PartnerPages/PartnerDetailPage'));
const PartnerPlusPage = lazy(() => import('./views/PartnerPages/PartnerPlusPage'));
const PartnershipDirectoryPage = lazy(() =>
  import('./views/PartnerPages/PartnershipDirectoryPage')
);
const PartnershipsPage = lazy(() => import('./views/PartnerPages/PartnershipsPage'));
const PartnershipsUniversitiesNonprofitsPage = lazy(() =>
  import('./views/PartnerPages/PartnershipsUniversitiesNonprofitsPage')
);
const RoundtablePage = lazy(() => import('./views/RoundtablePage/RoundtablePage'));
const AccessibilityStatementPage = lazy(() =>
  import('./views/UsefulLinkPages/AccessibilityStatementPage')
);
const CookiesPage = lazy(() => import('./views/UsefulLinkPages/CookiesPage'));
const PrivacyPage = lazy(() => import('./views/UsefulLinkPages/PrivacyPage'));
const SitemapPage = lazy(() => import('./views/UsefulLinkPages/SitemapPage'));
const TermsAndConditionsPage = lazy(() => import('./views/UsefulLinkPages/TermsAndConditionsPage'));
const LandingPage = lazy(() => import('./views/LandingPage/LandingPage'));

const renderCorePage = (page, pageId, cmsPageType) => (props) =>
  React.createElement(CorePageWrapper, { ...props, page, pageId, cmsPageType });

const AppRouter = () => {
  const PageRoute = ({ render, isProductRedirect, ...rest }) => (
    <Route {...rest} render={(props) => render({ ...props, isProductRedirect })} />
  );

  return (
    <Router forceRefresh={true}>
      <GoogleAnalyticsPageViewController />
      <ScrollToPageTopController />
      <CookieBot domainGroupId={COOKIEBOT_DOMAIN_GROUP_ID} />
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PageRoute
            path='/'
            render={renderCorePage(LandingPage, 'home')}
            exact
            isProductRedirect
          />
          <PageRoute
            path='/accounts/register'
            render={() => {
              redirectExternal(REGISTER_PAGE_ROUTE, 'replace');
            }}
            exact
          />
          <PageRoute
            path={ABOUT_US_PAGE_ROUTE}
            render={renderCorePage(AboutUsPage, 'about-us')}
            exact
          />
          <PageRoute
            path={OCTOBER_PANEL_PAGE_ROUTE}
            render={renderCorePage(AboutUsPage, 'october-panel')}
            exact
          />
          <PageRoute
            path={ON_DEMAND_DEMO_PAGE_ROUTE}
            render={renderCorePage(
              AboutUsPage,
              'how-to-unlock-the-value-of-hidden-talent-with-inclusively'
            )}
            exact
          />
          <PageRoute
            path={NEXT_GEN_WORKFORCE_PAGE_ROUTE}
            render={renderCorePage(
              AboutUsPage,
              'next-gen-workforce-why-culture-is-the-new-currency'
            )}
            exact
          />
          <PageRoute
            path={CANDIDATE_DEMO_PAGE_ROUTE}
            render={renderCorePage(AboutUsPage, 'how-inclusively-works-candidate-demo')}
            exact
          />
          <PageRoute
            path={DEIA_PAGE_ROUTE}
            render={renderCorePage(
              AboutUsPage,
              'deia-theres-no-such-thing-as-dei-without-accessibility'
            )}
            exact
          />
          <PageRoute
            path={ABLE_TODAY_ADA_WEBINAR_PAGE_ROUTE}
            render={renderCorePage(AboutUsPage, 'able-today-ada-webinar')}
            exact
          />
          <PageRoute
            path={FIND_MEANINGFUL_EMPLOYMENT_FOR_THE_JOB_SEEKERS_YOU_SUPPORT}
            render={renderCorePage(
              AboutUsPage,
              'find-meaningful-employment-for-the-job-seekers-you-support-with-inclusively'
            )}
            exact
          />
          <PageRoute
            path={ACCESSIBILITY_STATEMENT_PAGE_ROUTE}
            render={renderCorePage(AccessibilityStatementPage, 'accessibility-statement')}
            exact
          />
          <PageRoute
            path={COMMUNITY_GUIDELINES_ROUTE}
            render={renderCorePage(AccessibilityStatementPage, 'community-guidelines')}
            exact
          />
          <PageRoute
            path={COMPANY_DIRECTORY_PAGE_ROUTE}
            render={renderCorePage(CompanyDirectoryPage, 'company-directory')}
            exact
          />
          <PageRoute
            path={COOKIES_PAGE_ROUTE}
            render={renderCorePage(CookiesPage, 'cookies-statement')}
            exact
          />
          <PageRoute
            path={COVID_19_RESPONSE_PAGE_ROUTE}
            render={renderCorePage(Covid19Page, 'banner')}
            exact
          />
          <PageRoute
            path={FAQ_PAGE_ROUTE}
            render={renderCorePage(FAQsPage, 'frequently-asked-questions')}
            exact
          />
          <PageRoute
            path={ADVOCATE_PORTAL_RESOURCE_PAGE_ROUTE}
            render={renderCorePage(
              AdvocatePortalResourcePage,
              'getting-started-with-the-advocate-portal'
            )}
            exact
          />
          <PageRoute
            path={FOR_ADVOCATES_PAGE_ROUTE}
            render={renderCorePage(ForAdvocatesPage, 'for-advocates')}
            exact
          />
          <PageRoute
            path={FOR_CANDIDATES_PAGE_ROUTE}
            render={renderCorePage(ForCandidatesPage, 'for-candidates')}
            exact
          />
          <PageRoute
            path={FOR_EMPLOYERS_PAGE_ROUTE}
            render={renderCorePage(ForEmployersPage, 'for-employers')}
            exact
          />
          <PageRoute path={JOB_PAGE_ROUTE} render={renderCorePage(JobPage)} exact />
          <PageRoute
            path={EMPLOYER_DISPLAY_PAGE_ROUTE}
            render={renderCorePage(EmployerDisplayPage)}
            exact
          />
          <PageRoute
            path={NEWS_AND_RESOURCES_PARENT_ROUTE}
            render={renderCorePage(NewsAndResourcesPage, 'news-and-resources')}
            exact
          />
          <PageRoute
            path={NEWS_AND_RESOURCES_DETAIL_PAGE_ROUTE}
            render={renderCorePage(NewsAndResourcesDetailPage, null, 'news_detail')}
          />
          <PageRoute path={NOT_FOUND_PAGE_ROUTE} render={renderCorePage(NotFoundPage)} exact />
          <Route path={ROUNDTABLE_ROUTES} render={renderCorePage(RoundtablePage)} exact />
          <PageRoute
            path={PARTNER_DETAIL_PAGE_ROUTE}
            render={renderCorePage(PartnerDetailPage, null, 'partner')}
            exact
          />
          <PageRoute
            path={PARTNER_PLUS_PAGE_ROUTE}
            render={renderCorePage(PartnerPlusPage, null, 'partner_plus')}
            exact
          />
          <PageRoute
            path={PARTNERSHIP_DIRECTORY_PAGE_ROUTE}
            render={renderCorePage(PartnershipDirectoryPage, 'partnership-directory')}
            exact
          />
          <PageRoute
            path={PARTNERSHIPS_PAGE_ROUTE}
            render={renderCorePage(PartnershipsPage, 'partnerships')}
            exact
          />
          <PageRoute
            path={PARTNERSHIPS_UNI_NONPROFITS_PAGE_ROUTE}
            render={renderCorePage(
              PartnershipsUniversitiesNonprofitsPage,
              'partnerships-universities-nonprofits'
            )}
            exact
          />
          <PageRoute
            path={PRIVACY_PAGE_ROUTE}
            render={renderCorePage(PrivacyPage, 'privacy-notice')}
            exact
          />
          <PageRoute path={SITEMAP_PAGE_ROUTE} render={renderCorePage(SitemapPage)} exact />
          <PageRoute
            path={TERMS_AND_CONDITIONS_PAGE_ROUTE}
            render={renderCorePage(TermsAndConditionsPage, 'terms-and-conditions')}
            exact
          />
          <Redirect to={NOT_FOUND_PAGE_ROUTE} />
        </Switch>
      </Suspense>
    </Router>
  );
};
export default AppRouter;
