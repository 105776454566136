import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import LogoBlack from '../../assets/logo/logo-dark-inclusively.svg';
import FacebookIcon from '../../assets/svg-icons/social-facebook.svg';
import InstagramIcon from '../../assets/svg-icons/social-instagram.svg';
import LinkedinIcon from '../../assets/svg-icons/social-linkedin.svg';
import TwitterIcon from '../../assets/svg-icons/social-twitter.svg';
import getYear from '../../utils/getYear';
import FooterEmailSubscriptionForm from '../FooterEmailSubscriptionForm/FooterEmailSubscriptionForm';
import LinkContainer from './LinkContainer';
import useStyles from './PageFooter.styles';

const PageFooter = ({ cmsData, socialMedia }) => {
  const classes = useStyles();

  if (!cmsData) {
    return null;
  }

  return (
    <footer className={classes.root}>
      <div className={classes.contentContainer}>
        <Typography className={classes.srOnly} component='h2'>
          Footer
        </Typography>
        <Grid container item={true} className={classes.footerLinkContainer}>
          <Grid container item={true} justifyContent='center' md={12} lg={4}>
            <FooterEmailSubscriptionForm />
          </Grid>
          <Grid item md={12} lg={8}>
            <LinkContainer cmsData={cmsData} />
          </Grid>
        </Grid>

        <Grid container className={classes.footerBottomRow}>
          <Grid item sm={12} md={4} className={classes.logoContainer}>
            <img alt='Inclusively' src={LogoBlack} className={classes.logo} />
          </Grid>
          <Grid item sm={12} md={4}>
            <p className={classes.copyright}>
              Copyright &#169; 2019-{getYear()}. Ligilo. All rights reserved.
            </p>
          </Grid>
          <Grid item sm={12} md={4}>
            <Grid container spacing={2} className={classes.socialIconContainer}>
              <Grid item>
                <a
                  href='https://www.facebook.com/workInclusively/'
                  className={classes.socialLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    alt='Inclusively on Facebook'
                    src={FacebookIcon}
                    className={classes.socialIcon}
                  />
                </a>
              </Grid>
              <Grid item>
                <a
                  href='https://twitter.com/workInclusively'
                  className={classes.socialLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    alt='Inclusively on Twitter'
                    src={TwitterIcon}
                    className={classes.socialIcon}
                  />
                </a>
              </Grid>
              <Grid item>
                <a
                  href='https://www.instagram.com/workinclusively/'
                  className={classes.socialLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    alt='Inclusively on Instagram'
                    src={InstagramIcon}
                    className={classes.socialIcon}
                  />
                </a>
              </Grid>
              <Grid item>
                <a
                  href='https://www.linkedin.com/company/workinclusively'
                  className={classes.socialLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    alt='Inclusively on Linkedin'
                    src={LinkedinIcon}
                    className={classes.socialIcon}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};
export default PageFooter;
