import { useContext } from 'react';
import { useQuery } from 'react-query';

import getCmsCollectionData from '../api/getCmsCollectionData';
import { LocaleContext } from '../context/LocaleContext';

const queryFn = async (key, collectionId, locale) => getCmsCollectionData({ collectionId, locale });

const useCmsCollectionQuery = (collectionId) => {
  const {
    state: { locale },
  } = useContext(LocaleContext);
  const readyToMakeQuery = collectionId;
  return useQuery(readyToMakeQuery && ['cms-collection', collectionId, locale], queryFn);
};
export default useCmsCollectionQuery;
