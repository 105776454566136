import * as Sentry from '@sentry/browser';
import React from 'react';

import PageErrorMessage from './PageErrorMessage';

class PageErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <PageErrorMessage />;
    }
    return this.props.children;
  }
}

export default PageErrorBoundary;
