import { useContext } from 'react';
import { useQuery } from 'react-query';

import getEmployer from '../api/getEmployer';
import { LoginDetailsContext } from '../context/LoginDetailsContext';

const queryFn = async (key, userAccessToken, employerSlug) =>
  getEmployer({ userAccessToken, employerSlug });

const useEmployerQuery = (employerSlug) => {
  const {
    state: { loginDetails },
  } = useContext(LoginDetailsContext);
  let params = [];
  if (loginDetails && loginDetails.token) {
    params = ['auth-employer-profile', loginDetails.token, employerSlug];
  } else {
    params = ['employer-profile', '', employerSlug];
  }
  return useQuery(params, queryFn);
};
export default useEmployerQuery;
