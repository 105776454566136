import React, { createContext, useReducer } from 'react';

const initialState = {
  locale: 'en',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOCALE':
      return {
        LOCALE: action.payload,
      };
    default:
      throw new Error();
  }
};

export const LocaleContext = createContext(initialState);

export const LocaleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <LocaleContext.Provider value={{ state, dispatch }}>{children}</LocaleContext.Provider>;
};
