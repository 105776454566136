import { Link } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RouteLink = ({
  className,
  route,
  label,
  ariaLabel,
  arrow,
  hash,
  handleBannerClose,
  ...linkProps
}) => {
  const history = useHistory();
  return (
    <Link
      {...linkProps}
      className={className}
      href='#'
      onClick={(evt) => {
        evt.preventDefault();
        const path = hash ? `${route}#${hash}` : route;
        history.push(path);
        if (handleBannerClose) {
          handleBannerClose();
        }
      }}
      aria-label={ariaLabel}
    >
      {arrow && <ArrowBackIcon />}
      {label}
    </Link>
  );
};
export default RouteLink;
