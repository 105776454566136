export const container = (theme) => ({
  margin: '0 auto',
  maxWidth: MAX_PAGE_CONTENT_WIDTH,
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
});

export const textEllipsis = (numLines) => ({
  display: '-webkit-box',
  '-webkit-line-clamp': numLines,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});

export const pageContentContainer = (theme) => ({
  flexGrow: 1,
});

export const singleColumnPage = (theme) => ({
  ...container(theme),
  maxWidth: '56rem',
});

export const MAX_PAGE_CONTENT_WIDTH = '84rem';
export const HALF_MAX_PAGE_CONTENT_WIDTH = '42rem';
