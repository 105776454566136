import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

import useStyles from './PageLoadingIndicator.styles';

const PageLoadingIndicator = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.indicatorContainer}>
        <LinearProgress color='secondary' />
      </div>
    </div>
  );
};
export default PageLoadingIndicator;
