import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  toplineItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toplineItem: {
    fontSize: '14px',
    lineHeight: '18px',
    opacity: '0.8',
  },
  successEnablers: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '1.5rem 0 0 0',
    padding: '1rem 0 0 0',
    borderTop: `1px solid ${theme.palette.lightgray2}`,
    '& > li': {
      marginBottom: '0.5rem',
      '&:not(:last-of-type)': {
        marginRight: '0.5rem',
      },
    },
  },
}));
