import { createTheme } from '@material-ui/core/styles';

import colors from '../src/styles/_colors.scss';

export default createTheme({
  palette: {
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.electricViolet,
    },
    success: {
      main: colors.success,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    text: {
      primary: colors.ebonyClay,
      secondary: colors.fiord,
    },
    black: colors.ebonyClay,
    gray: colors.riverBed,
    lightgray: colors.whiteLilac,
    lightgray2: colors.whisper,
    inputgray: colors.inputGray,
    purple: colors.electricViolet,
    purplehover: colors.purpleHeart,
    lightpurple: colors.fog,
    lightpurple2pt: colors.magnolia,
    lightpurple4pt: colors.blueChalk,
    turquoise: colors.spray,
  },
});
