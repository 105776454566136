import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';

import { useStyles } from './ImageLightbox.styles';

// Sample of data format to pass into lighbox library:
// const imageList = [
//   {
//     original: 'https://picsum.photos/id/1018/1000/600/',
//     originalAlt: 'company culture',
//   },
//   {
//     original: 'https://picsum.photos/id/1015/1000/600/',
//     originalAlt: 'company culture',
//   },
// ];

const ImageLightbox = ({ images }) => {
  const classes = useStyles();
  const [showLightbox, setShowLightbox] = useState(false);

  const handleOpen = () => {
    setShowLightbox(true);
  };

  const handleClose = () => {
    setShowLightbox(false);
  };

  let imageList = [];
  images.map((el) => {
    const formattedImageData = {
      original: el.image,
      originalAlt: el.image_alt_text,
      srcSet: `${el.image} 600w, ${el.image} 1200w`,
      sizes: '(max-width: 1000px) 600px, 1200px',
    };
    return imageList.push(formattedImageData);
  });

  return (
    <>
      <div className={images.length > 1 ? classes.gridImages : classes.gridImage}>
        {images.slice(0, 3).map((image, index) => (
          <div key={index} className={classes.gridImageOuter}>
            <div
              role='img'
              aria-label={image.image_alt_text}
              className={classes.gridImageInner}
              style={{ backgroundImage: `url('${image.image}')` }}
            ></div>
            {images.length > 2 && index + 1 === 3 && (
              <button
                className={classes.gridImageButton}
                type='button'
                onClick={handleOpen}
                aria-haspopup='dialog'
                aria-controls={showLightbox ? 'popup-lightbox' : null}
                aria-expanded={showLightbox}
              >
                View All Photos
              </button>
            )}
          </div>
        ))}
      </div>

      <Dialog
        aria-label='photo gallery'
        className={classes.lightboxModal}
        open={showLightbox}
        PaperProps={{ id: 'popup-lightbox', 'aria-modal': 'true' }}
      >
        <div className={classes.paper}>
          <IconButton
            aria-label='close popup'
            onClick={handleClose}
            className={classes.lightboxClose}
          >
            <CloseIcon />
          </IconButton>
          <ImageGallery
            items={imageList}
            showThumbnails={false}
            showPlayButton={false}
            showIndex={true}
          />
        </div>
      </Dialog>
    </>
  );
};
export default ImageLightbox;
