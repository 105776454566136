import { Tooltip } from '@material-ui/core';
import React from 'react';

import { ReactComponent as AccessibleBuildingIcon } from '../../assets/svg-icons/accommodations/AccessibleBuilding.svg';
import { ReactComponent as AccessibleDevicesIcon } from '../../assets/svg-icons/accommodations/AccessibleDevices.svg';
import { ReactComponent as AccessibleWorkEnvironmentIcon } from '../../assets/svg-icons/accommodations/AccessibleWorkEnvironment.svg';
import { ReactComponent as AirQualityIcon } from '../../assets/svg-icons/accommodations/AirQuality.svg';
import { ReactComponent as AlternativeInputIcon } from '../../assets/svg-icons/accommodations/AlternativeInput.svg';
import { ReactComponent as BehavioralSupportIcon } from '../../assets/svg-icons/accommodations/BehavioralSupport.svg';
import { ReactComponent as InPersonLiveAssistanceIcon } from '../../assets/svg-icons/accommodations/InPersonOrLiveAssistance.svg';
import { ReactComponent as InterviewingPreferencesIcon } from '../../assets/svg-icons/accommodations/InterviewingPreferences.svg';
import { ReactComponent as JobModificationIcon } from '../../assets/svg-icons/accommodations/JobModification.svg';
import { ReactComponent as JobRestructuringIcon } from '../../assets/svg-icons/accommodations/JobRestructuring.svg';
import { ReactComponent as ModifiedBreaksIcon } from '../../assets/svg-icons/accommodations/ModifiedBreaks.svg';
import { ReactComponent as OnboardingIcon } from '../../assets/svg-icons/accommodations/Onboarding.svg';
import { ReactComponent as ServiceAnimalIcon } from '../../assets/svg-icons/accommodations/ServiceAnimalEmotionalSupportAnimal.svg';
import { ReactComponent as SoftwareDevicesIcon } from '../../assets/svg-icons/accommodations/Software&Devices.svg';
import useStyles from './SuccessEnablerIcon.styles';

const iconLookup = {
  'Accessible Building': {
    class: AccessibleBuildingIcon,
    label: 'Accessible Building',
  },
  'Accessible Devices': {
    class: AccessibleDevicesIcon,
    label: 'Accessible Devices',
  },
  'Accessible Work Environment': {
    class: AccessibleWorkEnvironmentIcon,
    label: 'Accessible Work Environment',
  },
  'Air Quality': {
    class: AirQualityIcon,
    label: 'Air Quality',
  },
  'Alternative Input': {
    class: AlternativeInputIcon,
    label: 'Alternative Input',
  },
  'Behavioral Support': {
    class: BehavioralSupportIcon,
    label: 'Behavioral Support',
  },
  'In Person or Live Assistance': {
    class: InPersonLiveAssistanceIcon,
    label: 'In Person or Live Assistance',
  },
  'Interview Preferences': {
    class: InterviewingPreferencesIcon,
    label: 'Interview Preferences',
  },
  'Job Modification': {
    class: JobModificationIcon,
    label: 'Job Modification',
  },
  'Job Restructuring': {
    class: JobRestructuringIcon,
    label: 'Job Restructuring',
  },
  'Modified Breaks': {
    class: ModifiedBreaksIcon,
    label: 'Modified Breaks',
  },
  Onboarding: {
    class: OnboardingIcon,
    label: 'Onboarding',
  },
  'Service Animal/Emotional Support Animal': {
    class: ServiceAnimalIcon,
    label: 'Service Animal / Emotional Support Animal',
  },
  'Software & Devices': {
    class: SoftwareDevicesIcon,
    label: 'Software & Devices',
  },
  'Telework / Remote Work / Virtual Office': {
    class: AccessibleDevicesIcon,
    label: 'Telework / Remote Work / Virtual Office',
  },
};

const SuccessEnablerIcon = ({ iconName, includeTooltip = true }) => {
  const classes = useStyles();
  const icon = iconLookup[iconName];
  if (!icon) {
    return null;
  }
  let content;
  if (includeTooltip) {
    content = (
      <Tooltip title={icon.label} placement='right-start'>
        {React.createElement(icon.class)}
      </Tooltip>
    );
  } else {
    content = React.createElement(icon.class);
  }
  return <div className={classes.root}>{content}</div>;
};
export default SuccessEnablerIcon;
