import React, { useState } from 'react';

export const KEY_ADVOCATE_CANDIDATE = 'ADVOCATE_CANDIDATE';

const getInitialAdvocateCandidate = () => {
  const candidateData = localStorage.getItem(KEY_ADVOCATE_CANDIDATE);
  const advocateCandidate = candidateData ? JSON.parse(candidateData) : null;
  return advocateCandidate;
};

export const AdvocateCandidateContext = React.createContext({
  advocateCandidate: null,
  setAdvocateCandidate: (candidate) => {},
});

export const AdvocateCandidateContextProvider = ({ children }) => {
  const [advocateCandidate, setAdvocateCandidate] = useState(getInitialAdvocateCandidate());

  const setAdvocateCandidateWithStorage = (candidate) => {
    console.log('SET ADVOCATE CANDIDATE CONTEXT: ', candidate);
    localStorage.setItem(KEY_ADVOCATE_CANDIDATE, JSON.stringify(candidate));
    setAdvocateCandidate(candidate);
  };

  return (
    <AdvocateCandidateContext.Provider
      value={{ advocateCandidate, setAdvocateCandidate: setAdvocateCandidateWithStorage }}
    >
      {children}
    </AdvocateCandidateContext.Provider>
  );
};
