import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import useStyles from './PageErrorMessage.styles';

const PageErrorMessage = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Paper className={classes.messageContainer}>
        <Typography variant='h4' className={classes.header}>
          There was an unexpected problem
        </Typography>
        <Typography variant='subtitle1' className={classes.body} color='textSecondary'>
          Please refresh the page and try again
        </Typography>
      </Paper>
    </div>
  );
};
export default PageErrorMessage;
