import { Button, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FocusTrap from 'focus-trap-react';
import React, { useEffect, useState } from 'react';

import Arrow from '../../assets/svg-icons/arrow-caret.svg';
import {
  FOR_ADVOCATES_PAGE_ROUTE,
  FOR_CANDIDATES_PAGE_ROUTE,
  FOR_EMPLOYERS_PAGE_ROUTE,
} from '../../Routes';

const useStyles = makeStyles((theme) => ({
  corePagesButton: {
    minWidth: 'unset',
    margin: '0 6px',
    padding: '2px',
    fontSize: '0.875rem',
    fontWeight: 700,
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    borderTop: '4px solid transparent',
    borderBottom: '4px solid transparent',
    '&.expanded .arrow-icon': {
      transform: 'rotate(180deg)',
    },
    '& img': {
      marginLeft: '4px',
      height: '4px',
      width: '7px',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      borderBottom: `4px solid ${theme.palette.purple}`,
    },
  },
  listItems: {
    position: 'absolute',
    top: '46px',
    right: '-16px',
    border: `1px solid ${theme.palette.lightgray2}`,
    borderRadius: '8px',
    background: 'white',
    listStyle: 'none',
    boxShadow: '0px 2px 8px rgba(74, 79, 105, 0.15)',
  },
  listItem: {
    padding: 0,
  },
  itemText: {
    display: 'flex',
    width: '100%',
    padding: '0.75rem 1.5rem',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: 1,
    color: theme.palette.black,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    border: '2px solid transparent',
    backgroundColor: 'transparent',
    outline: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.lightgray,
    },
    '&:focus': {
      borderColor: theme.palette.purple,
    },
  },
}));

const CorePageMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // close dropdown if user presses escape key
  const escPress = (evt) => {
    if (evt.key === 'Escape') {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escPress, false);

    return () => {
      document.removeEventListener('keydown', escPress, false);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <FocusTrap
        active={open}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
        }}
      >
        <div style={{ position: 'relative' }}>
          <Button
            aria-controls={open ? 'core-page-menu' : null}
            aria-expanded={open}
            onClick={() => setOpen(!open)}
            className={`${classes.corePagesButton}${open ? ' expanded' : ''}`}
          >
            How It Works
            <img src={Arrow} className='arrow-icon' alt='' />
          </Button>
          {open && (
            <ul className={classes.listItems} id='core-page-menu' aria-label='How it Works pages'>
              <li className={classes.listItem}>
                <a href={FOR_CANDIDATES_PAGE_ROUTE} className={classes.itemText}>
                  For Candidates
                </a>
              </li>
              <li className={classes.listItem}>
                <a href={FOR_EMPLOYERS_PAGE_ROUTE} className={classes.itemText}>
                  For Employers
                </a>
              </li>
              <li className={classes.listItem}>
                <a href={FOR_ADVOCATES_PAGE_ROUTE} className={classes.itemText}>
                  For Advocates
                </a>
              </li>
            </ul>
          )}
        </div>
      </FocusTrap>
    </ClickAwayListener>
  );
};
export default CorePageMenu;
