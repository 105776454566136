import Typography from '@material-ui/core/Typography';
import React from 'react';

import useStyles from './LinkContainer.styles';

const LinkContainer = ({ cmsData, socialMedia }) => {
  const classes = useStyles();

  const LinkBlock = ({ header, links, index }) => {
    return (
      <ul className={classes.linkList}>
        <li>
          <Typography className={classes.linkHeader} component='h3'>
            {header}
          </Typography>
        </li>
        {links.map((link) => {
          const externalLink = link.link_url.startsWith('http');

          return (
            <li key={link.link_title} className={classes.linkBody}>
              {externalLink ? (
                <a
                  data-cy={'page-footer-' + link.link_title.replace(/ /g, '')}
                  href={link.link_url}
                  className={classes.linkAnchor}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {link.link_title}
                </a>
              ) : (
                <a
                  data-cy={'page-footer-' + link.link_title.replace(/ /g, '')}
                  href={link.link_url}
                  className={classes.linkAnchor}
                >
                  {link.link_title}
                </a>
              )}
            </li>
          );
        })}
        {/*
        {index === 0 && (
          <li key='phone' className={classes.linkBody}>
            <a href='tel:8334462587' className={classes.linkAnchor} data-cy='page-footer-phone'>
              (833) 4IN-CLUS
            </a>
          </li>
        )}
        */}
      </ul>
    );
  };

  return (
    <ul className={classes.linkContainer}>
      {cmsData.map(({ headline, links }, idx) => {
        return (
          <li key={idx} className={classes.bottomNavLinks}>
            <LinkBlock header={headline} links={links} index={idx} />
          </li>
        );
      })}
    </ul>
  );
};

export default LinkContainer;
