import { makeStyles } from '@material-ui/core/styles';
import merge from 'lodash.merge';

import { container, pageContentContainer } from '../../MixinsAndVars.styles';

export const useStyles = makeStyles((theme) => ({
  root: merge(container(theme), pageContentContainer(theme), {
    position: 'relative',
    marginBottom: '4rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '8rem',
    },
  }),
  pageContainer: {
    flexGrow: 1,
    maxWidth: '60rem',
    margin: '0 auto',
    '& hr': {
      marginTop: '3rem',
    },
  },
  pageContentContainer: {
    position: 'relative',
    maxWidth: '43rem',
    margin: '0 auto',
  },
  header: {
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  headerPrimary: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      flexDirection: 'row',
    },
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginTop: '4px',
    marginBottom: '4px',
    marginRight: '2rem',
    width: 'auto',
    height: 'auto',
    maxWidth: '100px',
    maxHeight: '60px',
  },
  location: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWebsite: {
    width: '155px',
    height: '56px',
    fontSize: '18px',
    flexShrink: 0,
    marginTop: '4px',
    border: '2px solid transparent',
    borderRadius: '2.5rem',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2rem',
    },
    '& .MuiTouchRipple-root span': {
      display: 'none',
    },
    '&:focus': {
      color: theme.palette.purple,
      backgroundColor: 'white',
      borderColor: theme.palette.purple,
    },
  },
  overviewHeader: {
    marginTop: '4rem',
  },
  bodycopy: {
    marginTop: '1.25rem',
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.5,
    '& h3': {
      fontSize: '1.125rem', // 18px
    },
    '& ul': {
      marginTop: '1.5rem',
      paddingLeft: '1.5rem',
    },
    '& li': {
      position: 'relative',
      marginBottom: '0.8rem',
      listStyle: 'none',
      color: theme.palette.text.secondary,
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: 'normal',
      '&::before': {
        display: 'block',
        content: '" "',
        height: '0.5rem',
        width: '0.5rem',
        position: 'absolute',
        left: '-1.5rem',
        top: '.6rem',
        borderRadius: '2px',
        backgroundColor: theme.palette.purple,
      },
    },
  },
  links: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    lineHeight: 1.2,
    borderLeft: `10px solid ${theme.palette.lightgray}`,
    '& a': {
      display: 'inline-block',
      padding: '6px',
      color: theme.palette.gray,
      outline: 0,
      border: '2px solid transparent',
      borderRadius: '4px',
      transition: '0.2s ease',
      '&:hover': {
        backgroundColor: theme.palette.lightpurple2pt,
      },
      '&:focus': {
        backgroundColor: theme.palette.lightpurple2pt,
        borderColor: theme.palette.purple,
      },
    },
  },
  successEnablerHeader: {
    marginTop: '4rem',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      transform: 'none',
    },
  },
  successEnablers: {
    listStyle: 'none',
    padding: 0,
  },
  successEnablerRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    borderBottom: `1px solid ${theme.palette.lightgray2}`,
    '& > div': {
      width: 'auto',
      height: 'auto',
    },
    '& svg': {
      display: 'block',
      width: '40px',
      height: '40px',
      marginRight: '0.8rem',
      flexShrink: 0,
    },
    '& img': {
      display: 'block',
      width: '40px',
      height: '40px',
      marginRight: '0.8rem',
      flexShrink: 0,
      borderRadius: '50%',
    },
    '& > a': {
      padding: '6px',
      color: theme.palette.black,
      borderRadius: '4px',
      border: '2px solid transparent',
      outline: 0,
      transition: '0.2s ease',
      '&:hover': {
        backgroundColor: theme.palette.lightpurple2pt,
      },
      '&:focus': {
        backgroundColor: theme.palette.lightpurple2pt,
        borderColor: theme.palette.purple,
      },
    },
  },
  successEnablerDisclaimer: {
    marginTop: '1.5rem',
    color: theme.palette.gray,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  grid: {
    marginTop: '2rem',
  },
  viewAllButton: {
    display: 'inline-block',
    marginTop: '1.5rem',
    padding: '0.5rem 1rem',
    color: theme.palette.black,
    fontWeight: 500,
    fontSize: '1rem',
    textDecoration: 'none',
    outline: 0,
    border: '2px solid transparent',
    backgroundColor: theme.palette.lightgray2,
    borderRadius: '2.5rem',
    cursor: 'pointer',
    transition: '0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.lightgray,
    },
    '&:focus': {
      borderColor: theme.palette.black,
      backgroundColor: 'white',
    },
  },
  backButton: {
    '& .MuiTouchRipple-root span': {
      opacity: 0.25,
      transform: 'scale(1.2)',
    },
  },
}));
