import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import scrollToTopOfPage from '../../utils/scrollToTopOfPage';

const ScrollToPageTopController = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    scrollToTopOfPage();
  }, [pathname]);
  return null;
};
export default ScrollToPageTopController;
