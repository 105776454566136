export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const GOOGLE_ADWORDS_ID = process.env.REACT_APP_GOOGLE_ADWORDS_ID;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const COOKIEBOT_DOMAIN_GROUP_ID = process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID;
export const IADMIN_URL = process.env.REACT_APP_IADMIN_URL;
export const UNLEASH_ENV = process.env.REACT_APP_UNLEASH_ENV;
export const UNLEASH_URL = process.env.REACT_APP_UNLEASH_URL;
export const UNLEASH_SECRET = process.env.REACT_APP_UNLEASH_SECRET;
export const SUCCESS_ENABLEMENT_TEAM_ACCOUNT_NAME = 'Success Enablement Team';
export const SUCCESS_ENABLEMENT_TEAM_ACCOUNT_PUBID =
  process.env.REACT_APP_SUCCESS_ENABLEMENT_TEAM_ACCOUNT_PUBID;
export const IMGIX_IMAGE_DOMAIN = process.env.IMGIX_IMAGE_DOMAIN;
export const REACT_APP_V2_DOMAIN_PREFIX = process.env.REACT_APP_V2_DOMAIN_PREFIX;
export const REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const JSON_MIME_TYPE = 'application/json';
export const REACT_APP_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

export const PLEASE_REFRESH_THE_PAGE_ERROR_MESSAGE =
  'There was a problem, please refresh the page and try again';

export const TEXT_INPUT_DEBOUNCE_INTERVAL = 500;

export const SNACKBAR_AUTO_HIDE_DURATION = 20000;

export const RESUME_ACCEPTED_FILE_TYPES = {
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  // 'application/rtf': 'rtf',
  // 'application/txt': 'txt',
};

export const LOGIN_KEY_FOR_BROWSER_STORAGE = 'login-details';
export const VIEWED_CANDIDATES_KEY_PREFIX = 'viewed-candidates-';

export const REFERENCES = [
  {
    value: 'Google / Search Engine',
    label: 'Google / Search Engine',
  },
  {
    value: 'Facebook / Instagram',
    label: 'Facebook / Instagram',
  },
  {
    value: 'TikTok',
    label: 'TikTok',
  },
  {
    value: 'LinkedIn',
    label: 'LinkedIn',
  },
  {
    value: 'Twitter',
    label: 'Twitter',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'Friend / Family',
    label: 'Friend / Family',
  },
  {
    value: 'Article / Blog Post',
    label: 'Article / Blog Post',
  },
  {
    value: 'Employer Website',
    label: 'Employer Website',
  },
  {
    value: 'Nonprofit',
    label: 'Nonprofit',
  },
  {
    value: 'Contributor Series',
    label: 'Contributor Series',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const ADVOCATE_PENDING_LOGIN_MESSAGE =
  'Once your Advocate account is approved, you will be able to log in and create an Advocate profile. If you are unable to log in after 1-2 business days, or have any questions, please reach out to us.';
export const DISTANCES = ['5', '10', '15', '20', '25', '35', '50', '100'];
export const DEACTIVATION_REASONS = [
  {
    label: `I've found a job using Inclusively, and I'm no longer actively job seeking`,
    value: `I've found a job using Inclusively, and I'm no longer actively job seeking`,
  },
  {
    label: `I've found a job not using Inclusively, and I'm no longer actively job seeking`,
    value: `I've found a job not using Inclusively, and I'm no longer actively job seeking`,
  },
  {
    label: `I'm still actively job seeking, but there aren't any jobs on Inclusively that match my background/skillset
  `,
    value: `I'm still actively job seeking, but there aren't any jobs on Inclusively that match my background/skillset
  `,
  },
  {
    label: `I have connected to jobs on Inclusively, but I have not had success
  `,
    value: `I have connected to jobs on Inclusively, but I have not had success
  `,
  },
  {
    label: `I'm taking a break from job seeking
  `,
    value: `I'm taking a break from job seeking
  `,
  },
  {
    label: `None of the above
  `,
    value: `None of the above
  `,
  },
  {
    label: `I choose not to answer`,
    value: `I choose not to answer`,
  },
];
