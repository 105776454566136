import { Button, Divider, Grid, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Imgix from 'react-imgix';
import { useParams } from 'react-router-dom';

import ImageLightbox from '../../components/ImageLightbox/ImageLightbox';
import JobCard from '../../components/JobCard/JobCard';
import LineBreaker from '../../components/LineBreaker/LineBreaker';
import SuccessEnablerIcon from '../../components/SuccessEnablerIcon/SuccessEnablerIcon';
import SuccessEnablerTooltip from '../../components/SuccessEnablerTooltip/SuccessEnablerTooltip';
import useEmployerQuery from '../../hooks/useEmployerQuery';
import { JOBS_PAGE_ROUTE, makeLoginWithRedirectRoute } from '../../Routes';
import imageUrl from '../../utils/images';
import { useStyles } from './EmployerDisplayPage.styles';
const EmployerDisplayPage = ({ user }) => {
  const classes = useStyles();
  const { employerSlug } = useParams();
  const [displayNumber] = useState(4);
  const [moreToDisplay, setMoreToDisplay] = useState(false);

  const { data: employer, status } = useEmployerQuery(employerSlug);

  const isDataLoading = status === 'loading';

  const employerJobs = (employer && employer.positions) || null;

  useEffect(() => {
    if (employer && employer.positions_count && employer.positions_count > 4) {
      setMoreToDisplay(true);
    }
  }, [employer]);

  if (employerJobs) {
    employerJobs.forEach((job) => {
      job.employer = employer;
    });
  }

  const visitWebsite = () => {
    const win = window.open(employer.url, '_blank');
    if (win) {
      win.focus();
    }
  };

  if (isDataLoading) {
    return <LinearProgress color='secondary' />;
  }

  const accommodationTypes = Array.from(
    new Set(employer.accommodations.map((accommodation) => accommodation.type))
  );

  const encodedEmployer = encodeURIComponent(employer.name);
  const searchRoute = `${JOBS_PAGE_ROUTE}?term=${encodedEmployer}`;

  const employerContent = (
    <>
      <Helmet>
        <title>{employer.name} - Inclusively</title>
      </Helmet>
      <div className={classes.pageContentContainer}>
        <div className={classes.header}>
          <div className={classes.headerPrimary}>
            {employer.logo && <img className={classes.logo} src={employer.logo} alt='' />}
            <div className={classes.headerInfo}>
              <Typography variant='h3' component='h1'>
                {employer.name}
              </Typography>
              {employer && (employer.locality || employer.region) && (
                <Typography variant='body2' color='textSecondary' className={classes.location}>
                  <LocationOnIcon />
                  {employer.locality && `${employer.locality}, `}
                  {employer.region}
                </Typography>
              )}
            </div>
          </div>
          {employer.url && (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => visitWebsite()}
              className={classes.buttonWebsite}
            >
              Visit website
            </Button>
          )}
        </div>
        {employer.images && employer.images.length > 0 && (
          <ImageLightbox images={employer.images} />
        )}
      </div>

      <Divider />
      <div className={classes.pageContentContainer}>
        {employer.about && (
          <>
            <Typography variant='h4' component='h2' className={classes.overviewHeader}>
              Overview
            </Typography>
            <div
              className={classes.bodycopy}
              dangerouslySetInnerHTML={{ __html: employer.about }}
            />
          </>
        )}

        {employer.links && employer.links.length > 0 && (
          <>
            <Typography variant='h4' component='h2' className={classes.overviewHeader}>
              Additional Links
            </Typography>
            {employer.links.map(({ link_title, url }) => (
              <Typography key={url} variant='body1' className={classes.links}>
                <a href={url} target='_blank' rel='noopener noreferrer'>
                  {link_title}
                </a>
              </Typography>
            ))}
          </>
        )}

        {accommodationTypes && accommodationTypes.length > 0 && (
          <>
            <div className={classes.successEnablerHeader}>
              <Typography variant='h4' component='h2'>
                Success Enablers
              </Typography>
              <SuccessEnablerTooltip />
            </div>
            <ul className={classes.successEnablers}>
              {accommodationTypes.map((type, index) => (
                <li key={index} className={classes.successEnablerRow}>
                  <SuccessEnablerIcon iconName={type} includeTooltip={false} />
                  {type}
                </li>
              ))}
            </ul>
          </>
        )}

        <Typography variant='body1' className={classes.successEnablerDisclaimer}>
          {employer.accommodations_disclaimer ? (
            <LineBreaker content={employer.accommodations_disclaimer} />
          ) : (
            <>
              Employer has committed to comply with all federal, state, and local equal opportunity
              laws and has committed to fair treatment and reasonable accommodation of applicants'
              disabilities. Employer's selected Success Enablers are not meant to be all-inclusive
              and may not be fully representative of Employer's ability to accommodate the needs of
              all individuals.
            </>
          )}
        </Typography>

        {employer.badges.length > 0 && (
          <>
            <Typography variant='h4' component='h2' className={classes.overviewHeader}>
              Inclusive Services
            </Typography>
            <ul className={classes.successEnablers}>
              {employer.badges.map((badge) => (
                <li key={badge.public_id} className={classes.successEnablerRow}>
                  {badge.icon && (
                    <Imgix
                      src={imageUrl(badge.icon)}
                      width={40}
                      htmlAttributes={{ alt: '' }}
                      className='lazyload'
                      attributeConfig={{
                        src: 'data-src',
                        srcSet: 'data-srcset',
                        sizes: 'data-sizes',
                      }}
                    />
                  )}
                  {badge.link ? <a href={badge.link}>{badge.name}</a> : badge.name}
                </li>
              ))}
            </ul>
          </>
        )}

        {employerJobs && employerJobs.length > 0 && (
          <section aria-label='relevant jobs'>
            <Typography variant='h4' component='h2' className={classes.successEnablerHeader}>
              Available positions at {employer.name}
            </Typography>
            <div className={classes.grid}>
              <Grid container spacing={3}>
                {employerJobs.slice(0, displayNumber).map((job) => (
                  <Grid key={job.public_id} item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <JobCard job={job} />
                  </Grid>
                ))}
              </Grid>
            </div>
            {moreToDisplay && user && (
              <a className={classes.viewAllButton} href={searchRoute}>
                View all positions
              </a>
            )}
            {!user && (
              <a className={classes.viewAllButton} href={makeLoginWithRedirectRoute(searchRoute)}>
                View all positions
              </a>
            )}
          </section>
        )}
      </div>
    </>
  );

  return (
    <div className={classes.root}>
      {/*
      {user && user.user_type !== 'Employer' && (
        <Button
          className={classes.backButton}
          startIcon={<ArrowBackIcon fontSize='inherit' />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      )}
      */}
      <div className={classes.pageContainer}>{employerContent}</div>
    </div>
  );
};
export default EmployerDisplayPage;
