import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '19rem',
    marginBottom: '4rem',
    [theme.breakpoints.up('lg')]: {
      marginRight: '1rem',
    },
    // styles for hubspot embed
    '& #hubspotNewsletterSignup': {
      position: 'relative',
      '& .hs_email > label': {
        color: '#4A4F69',
      },
      '& .hs-input': {
        width: 'calc(100% - 54px)',
        margin: 0,
        padding: '1rem 0.875rem',
        fontSize: '1.125rem',
        lineHeight: 1,
        backgroundColor: 'white',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.43)',
        outline: 0,
        transition: '0.2s ease',
        '&.error': {
          borderColor: theme.palette.error.main,
        },
        '&:hover': {
          borderColor: theme.palette.black,
        },
        '&:focus': {
          padding: 'calc(1rem - 1px) 0.875rem',
          borderWidth: '2px',
          borderColor: theme.palette.purple,
        },
      },
      '& .hs-button': {
        position: 'absolute',
        top: '24px',
        right: '-2px',
        height: '58px',
        width: '58px',
        padding: 0,
        fontSize: '0.1rem',
        color: theme.palette.purple,
        backgroundColor: theme.palette.purple,
        border: `2px solid ${theme.palette.purple}`,
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        outline: 0,
        cursor: 'pointer',
        transition: '0.2s ease',
        '&:focus': {
          color: 'white',
          backgroundColor: 'white',
        },
      },
      // arrow replacement on submit btn (arrow-forward.svg - uri encoded)
      '&::after': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '42px',
        right: '14px',
        height: '24px',
        width: '24px',
        paddingTop: '6px',
        pointerEvents: 'none',
        zIndex: 1,
        borderRadius: '50%',
        backgroundColor: theme.palette.purple,
        content: `url("data:image/svg+xml,%3Csvg width='14' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.207 8.707a1 1 0 000-1.414L6.843.929A1 1 0 005.43 2.343L11.086 8l-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM0 9h12.5V7H0v2z' fill='white'/%3E%3C/svg%3E")`,
      },
      '& .hs-error-msgs': {
        listStyle: 'none',
        '& label': {
          display: 'block',
          width: '100%',
          padding: '0.75rem 0.75rem 0 0.75rem',
          fontSize: '0.875rem',
          lineHeight: 1.2,
          color: theme.palette.error.main,
        },
      },
      '& .submitted-message': {
        width: '100%',
        position: 'relative',
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.125rem',
        textAlign: 'center',
        color: theme.palette.purple,
        background: theme.palette.lightgray,
        zIndex: 2,
      },
    },
  },
  linkHeader: {
    color: '#4A4F69',
    paddingBottom: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    color: '#4A4F69',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  input: {
    '& fieldset': {
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& input': {
      backgroundColor: 'white',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
  },
  button: {
    marginTop: '20px',
    height: '58px',
    width: '60px',
    minWidth: 0,
    backgroundColor: '#4A4F69',
    outline: 0,
    border: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: 'none',
  },
  submit: {
    color: 'white',
  },
}));
