import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { GOOGLE_ANALYTICS_ID } from '../../Constants';

const GoogleAnalyticsPageViewController = () => {
  const location = useLocation();
  const prevLocationRef = useRef();
  useEffect(() => {
    if (GOOGLE_ANALYTICS_ID) {
      const currentLocation = location.pathname + location.search;
      if (prevLocationRef.current !== currentLocation) {
        prevLocationRef.current = currentLocation;
        ReactGA.pageview(currentLocation);
      }
    }
  }, [location.pathname, location.search]);
  return null;
};
export default GoogleAnalyticsPageViewController;
