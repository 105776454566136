import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';

import useStyles from './FooterEmailSubscriptionForm.styles';

const FooterEmailSubscriptionForm = () => {
  const classes = useStyles();

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '7661167',
        formId: 'dbc57c8f-773e-4e4d-bf96-256f7ef6ac5b',
        target: '#hubspotNewsletterSignup',
      });
    }
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.linkHeader} component='h3'>
        Join Our Newsletter
      </Typography>
      <div id='hubspotNewsletterSignup'></div>
    </div>
  );
};
export default FooterEmailSubscriptionForm;
