import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  logo: {
    height: 'auto',
    maxHeight: '40px',
    width: 'auto',
    maxWidth: '100px',
  },
  title: {
    marginTop: '1.5rem',
    lineHeight: 1.2,
  },
  companyName: {
    marginTop: '8px',
    display: 'flex',
    fontSize: '14px',
    lineHeight: 1.2,
    color: theme.palette.gray,
  },
  metadataContainer: {
    width: '100%',
    marginTop: '10px',
  },
}));
