import { useContext } from 'react';
import { queryCache, useMutation } from 'react-query';

import updateCandidateSavedJobs from '../api/updateCandidateSavedJobs';
import { LoginDetailsContext } from '../context/LoginDetailsContext';

const useUpdateCandidateSavedJobsMutation = () => {
  const {
    state: { loginDetails },
  } = useContext(LoginDetailsContext);
  return useMutation(
    ({ body }) =>
      updateCandidateSavedJobs({
        userAccessToken: loginDetails.token,
        body,
      }),
    {
      onSuccess: () => queryCache.refetchQueries('user'),
    }
  );
};
export default useUpdateCandidateSavedJobsMutation;
