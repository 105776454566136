import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  tooltip: {
    padding: '6px',
    position: 'absolute',
    top: '20px',
    left: 'calc(50% + 105px)',
    transform: 'translateX(-50%)',
    '&.onboarding-inline': {
      top: '0',
      left: 'calc(50% + 180px)',
    },
    '&.dashboard-inline': {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      transform: 'translateX(8px)',
    },
    '&.filter-inline': {
      top: '0.5rem',
      left: 'unset',
      right: '-1rem',
    },
    '&.tab-header-inline': {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      marginTop: '3rem',
      marginBottom: '1.5rem',
      transform: 'none',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    '& .MuiDialog-container': {
      maxWidth: '600px',
      height: 'auto',
      maxHeight: 'calc(100% - 64px)',
      position: 'relative',
      margin: 0,
      borderRadius: '8px',
      backgroundColor: 'white',
      border: '2px solid white',
      outline: 0,
      '&:focus': {
        borderColor: theme.palette.purple,
      },
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: '1rem',
      [theme.breakpoints.up('sm')]: {
        padding: '2rem 3rem',
      },
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',
    border: 'none',
  },
  modalBody: {
    marginTop: '1rem',
  },
  icons: {
    width: '100%',
    maxWidth: '367px',
    margin: '3rem auto 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      flexGrow: 1,
      width: '100%',
      height: '100%',
      '&:not(:first-of-type)': {
        marginLeft: '10px',
      },
    },
  },
  primaryButton: {
    marginTop: '3rem',
    padding: '14px 20px',
    border: '2px solid transparent',
    '& .MuiTouchRipple-root span': {
      display: 'none',
    },
    '&:focus': {
      color: theme.palette.purple,
      borderColor: theme.palette.purple,
      backgroundColor: 'white',
    },
  },
}));
