import Typography from '@material-ui/core/Typography';
import React from 'react';

import { ReactComponent as WorkIcon } from '../../assets/svg-icons/work.svg';
import JobMetadata from '../JobMetadata/JobMetadata';
import useStyles from './JobBriefing.styles';

const JobBriefing = ({ job, showOverview, largeJobTitle, className }) => {
  const classes = useStyles();
  const position = job.title;
  const employerName = job.employer.name;
  const employerLogo = job.employer.logo;
  let locations = job.locations.map(({ locality, region }) => {
    if (locality && region) {
      return `${locality}, ${region}`;
    }
    if (region) {
      return region;
    }
    return null;
  });
  if (job.is_remote) {
    locations = ['Remote', ...locations];
  }
  // const locationsLabel = locations.filter((location) => location).join(' | ');
  let locationsLabel;
  if (locations.length < 6) {
    locationsLabel = locations.map((location) => location).join(' | ');
  } else {
    const locationsLabelList = locations
      .slice(0, 5)
      .map((location) => location)
      .join(' | ');
    locationsLabel = `${locationsLabelList} (view more)`;
  }

  return (
    <>
      <div className={classes.logoContainer}>
        {employerLogo ? (
          <img className={classes.logo} src={employerLogo} alt='' />
        ) : (
          React.createElement(WorkIcon)
        )}
      </div>
      <Typography variant='body2' className={classes.title} align='left'>
        {position}
      </Typography>
      <div className={classes.companyName}>
        <Typography variant='body2' align='left'>
          {employerName}
        </Typography>
      </div>
      <Typography variant='body2' className={classes.companyName} align='left'>
        {locationsLabel}
      </Typography>
      <div className={classes.metadataContainer}>
        <JobMetadata job={job} />
      </div>
    </>
  );
};
export default JobBriefing;
