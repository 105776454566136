import makeStyles from '@material-ui/core/styles/makeStyles';
import merge from 'lodash.merge';

import { container, pageContentContainer } from '../../MixinsAndVars.styles';

export default makeStyles((theme) => ({
  root: merge(container(theme), pageContentContainer(theme)),
  messageContainer: {
    maxWidth: '40rem',
    margin: '4rem auto 0',
    padding: '5rem 2rem',
  },
  header: {
    marginBottom: '1rem',
  },
  body: {
    marginBottom: '1.5rem',
  },
}));
