import {
  REACT_APP_LOGIN_URL,
  REACT_APP_V2_DOMAIN_PREFIX,
  SUCCESS_ENABLEMENT_TEAM_ACCOUNT_NAME,
  SUCCESS_ENABLEMENT_TEAM_ACCOUNT_PUBID,
} from './Constants';

export const ABOUT_US_PAGE_ROUTE = '/about-us';
export const ACCESSIBILITY_STATEMENT_PAGE_ROUTE = '/accessibility-statement';
export const ACCOUNTS_PARENT_ROUTE = '/accounts';
export const ACCOUNT_EMAIL_VERIFIED_PAGE_ROUTE = `${ACCOUNTS_PARENT_ROUTE}/email-verified`;
export const ADVOCATE_CANDIDATES_PAGE_ROUTE = '/advocate/candidates';
export const ADVOCATE_DASHBOARD_PAGE_ROUTE = '/advocate/dashboard';
export const ADVOCATE_PORTAL_RESOURCE_PAGE_ROUTE = '/getting-started-with-the-advocate-portal';
export const ADVOCATE_ANALYTICS_PAGE_ROUTE = '/advocate/analytics';
export const CONNECTED_JOBS_PAGE_ROUTE = '/user/connected-jobs';
export const CANDIDATES_PAGE_ROUTE = '/candidates';
export const COMMUNITY_GUIDELINES_ROUTE = '/community-guidelines';
export const COMMUNITY_PAGE_ROUTE = '/community';
export const COMMUNITY_BROWSE_TOPICS_PAGE_ROUTE = '/community/browse-topics';
export const COMPANY_DIRECTORY_PAGE_ROUTE = '/company-directory';
export const COOKIES_PAGE_ROUTE = '/cookies';
export const COVID_19_RESPONSE_PAGE_ROUTE = '/covid-19-response';
export const EMPLOYER_DASHBOARD_PAGE_ROUTE = '/employer/dashboard';
export const EMPLOYER_MANAGE_USERS_PAGE_ROUTE = '/employer/dashboard/manage-users';
export const EMPLOYER_SETTINGS_PAGE_ROUTE = '/employer/settings';
export const EMPLOYER_HOLISTICS_PAGE_ROUTE = '/employer/disability-pipeline-report';
export const EMPLOYER_PROFILE_ROUTE = `${REACT_APP_V2_DOMAIN_PREFIX}/employer/profile`;
export const EMPLOYER_DASHBOARD_ROUTE = `${REACT_APP_V2_DOMAIN_PREFIX}/employer/home`;
export const EMPLOYER_JOBS_PAGE_ROUTE = '/employer/jobs';
export const EMPLOYER_JOB_IMPORT_PAGE_ROUTE = '/employer/import-job';
export const EMPLOYER_DISPLAY_PAGE_ROUTE = '/employers/:employerSlug';
export const EMPLOYER_NEW_JOB_ROUTE = '/employer/new-job/details';
export const EMPLOYER_HUBSPOT_REGISTER_PAGE_ROUTE = '/employer/employer-register';
export const FAQ_PAGE_ROUTE = '/frequently-asked-questions';
export const FOR_ADVOCATES_PAGE_ROUTE = '/for-advocates';
export const FOR_CANDIDATES_PAGE_ROUTE = '/for-candidates';
export const FOR_EMPLOYERS_PAGE_ROUTE = '/for-employers';
export const FORGOT_PASSWORD_PAGE_ROUTE = `${ACCOUNTS_PARENT_ROUTE}/forgot-password`;
export const JOB_PAGE_ROUTE = `${process.env.REACT_APP_FRONTEND_API_URL}/job/:jobId`;
export const JOB_CONNECT_SUCCESS_PAGE_ROUTE = '/connect-success';
export const JOBS_PAGE_ROUTE = `${process.env.REACT_APP_FRONTEND_API_URL}/jobs`;
export const LOGIN_PAGE_ROUTE = `${ACCOUNTS_PARENT_ROUTE}/login`;
export const LOGIN_PAGE_URL = REACT_APP_LOGIN_URL;
export const CONTACT_SUCCESS_ENABLEMENT_TEAM_ROUTE = `/messages?new=${SUCCESS_ENABLEMENT_TEAM_ACCOUNT_PUBID}&name=${SUCCESS_ENABLEMENT_TEAM_ACCOUNT_NAME}`;
export const NEWS_AND_RESOURCES_PARENT_ROUTE = '/news-and-resources';
export const NEWS_AND_RESOURCES_DETAIL_PAGE_ROUTE = '/news-and-resources/:pageSlug';
export const NOT_FOUND_PAGE_ROUTE = '/not-found';
export const OCTOBER_PANEL_PAGE_ROUTE = '/october-panel';
export const ON_DEMAND_DEMO_PAGE_ROUTE =
  '/how-to-unlock-the-value-of-hidden-talent-with-inclusively';
export const NEXT_GEN_WORKFORCE_PAGE_ROUTE = '/next-gen-workforce-why-culture-is-the-new-currency';
export const CANDIDATE_DEMO_PAGE_ROUTE = '/how-inclusively-works-candidate-demo';
export const DEIA_PAGE_ROUTE = '/deia-theres-no-such-thing-as-dei-without-accessibility';
export const ABLE_TODAY_ADA_WEBINAR_PAGE_ROUTE = '/able-today-ada-webinar';
export const FIND_MEANINGFUL_EMPLOYMENT_FOR_THE_JOB_SEEKERS_YOU_SUPPORT =
  '/find-meaningful-employment-for-the-job-seekers-you-support-with-inclusively';
export const PARTNER_DETAIL_PAGE_ROUTE = '/partners/:pageSlug';
export const PARTNER_PLUS_PAGE_ROUTE = '/partnerships/:pageSlug';
export const PARTNERSHIP_DIRECTORY_PAGE_ROUTE = '/partnership-directory';
export const PARTNERSHIPS_PAGE_ROUTE = '/partnerships';
export const PARTNERSHIPS_UNI_NONPROFITS_PAGE_ROUTE = '/partnerships-universities-nonprofits';
export const PRIVACY_PAGE_ROUTE = '/privacy';
export const REGISTER_PAGE_ROUTE = `${process.env.REACT_APP_FRONTEND_API_URL}${ACCOUNTS_PARENT_ROUTE}/register`;
export const SAVED_CANDIDATES_PAGE_ROUTE = '/candidates/saved';
export const SAVED_JOBS_PAGE_ROUTE = '/user/saved-jobs';
export const RESET_PASSWORD_CONFIRMATION_ROUTE = `${ACCOUNTS_PARENT_ROUTE}/reset-password/confirm`;
export const SITEMAP_PAGE_ROUTE = '/sitemap';
export const USER_DASHBOARD_PAGE_ROUTE = '/user/dashboard';
export const USER_ONBOARDING_A_ROUTE = '/user/onboarding/1';
export const USER_ONBOARDING_B_ROUTE = '/user/onboarding/2';
export const USER_ONBOARDING_C_ROUTE = '/user/onboarding/3';
export const USER_PROFILE_ROUTE = '/user/profile';
export const USER_SETTINGS_PAGE_ROUTE = '/user/settings';
export const TERMS_AND_CONDITIONS_PAGE_ROUTE = '/terms-and-conditions';
export const ROUNDTABLE_ROUTES = [
  '/microsoft-roundtable',
  '/ernst-young-roundtable',
  '/dell-roundtable',
  '/jp-morgan-chase-roundtable',
  '/travelers-roundtable',
];

export const makeAdvocateCandidateRoute = (candidateId) =>
  `/advocate/candidates/?uid=${candidateId}`;
export const makeCommunityTopicRoute = (topic) => `/community/topics/${topic}`;
export const makeCandidateRoute = (candidateId, positionId) =>
  positionId ? `/candidates/${positionId}/${candidateId}` : `/candidates/${candidateId}`;
export const makeEmployerJobRoute = (jobSlug) => `/employer/jobs/${jobSlug}`;
export const makeJobRoute = (jobId) => `${process.env.REACT_APP_FRONTEND_API_URL}/job/${jobId}`;
export const makeEmployerRoute = (employerSlug) => `/employers/${employerSlug}`;
export const makePartnerRoute = (partnerSlug) => `/partners/${partnerSlug}`;
export const makeJobConnectRoute = (jobId) => `${makeJobRoute(jobId)}/connect`;
export const makeForgotPasswordEmailSentRoute = (email) =>
  `${ACCOUNTS_PARENT_ROUTE}/forgot-password/email-sent/${encodeURIComponent(email)}`;
export const makeLoginWithRedirectRoute = (redirectRoute) =>
  `${process.env.REACT_APP_LOGIN_URL}?redirect=${redirectRoute}`;
export const makeNewsDetailRoute = (newsSlug) => `/news-and-resources/${newsSlug}`;
