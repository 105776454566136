import makeBackendApiUrl from '../utils/makeBackendApiUrl';
import apiRequest from './apiRequest';

const getCmsPageData = async ({ contentId, locale, preview, pageType }) => {
  const previewId = preview ? preview : '0';
  if (pageType) {
    return apiRequest(
      makeBackendApiUrl(`/cms/page/${pageType}/${contentId}/${locale}/${previewId}/`),
      {}
    );
  }
  return apiRequest(makeBackendApiUrl(`/cms/page/${contentId}/${locale}/${previewId}/`), {});
};
export default getCmsPageData;
