import makeBackendApiUrl from '../utils/makeBackendApiUrl';
import apiRequest from './apiRequest';

const getUser = async ({ userAccessToken }) => {
  return apiRequest(makeBackendApiUrl(`/user/`), {
    headers: {
      Authorization: `Token ${userAccessToken}`,
    },
  });
};
export default getUser;
