import './core.scss';
import 'lazysizes';

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import FlagProvider from '@unleash/proxy-client-react';
import React from 'react';
import ReactGA from 'react-ga';
import { ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import useStyles from './App.styles';
import AppRouter from './AppRouter';
import AppTheme from './AppTheme';
import {
  GOOGLE_ANALYTICS_ID,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  UNLEASH_ENV,
  UNLEASH_SECRET,
  UNLEASH_URL,
} from './Constants';
import { AdvocateCandidateContextProvider } from './context/AdvocateCandidateContext';
import { EmployerContextProvider } from './context/EmployerContext';
import { LocaleContextProvider } from './context/LocaleContext';
import { LoginDetailsContextProvider } from './context/LoginDetailsContext';

const reactQueryConfig = {
  refetchAllOnWindowFocus: false,
};

if (GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.ga('set', 'anonymizeIp', true);
  ReactGA.ga('set', 'forceSSL', true);
} else {
  console.warn(
    "You don't have a Google Analytics ID specified in your environment variables. GA Tracking is disabled."
  );
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing()],
  blacklistUrls: ['http://localhost:3000'],
  tracesSampleRate: 1.0,
});

const unleashedConfig = {
  url: UNLEASH_URL,
  clientKey: UNLEASH_SECRET,
  refreshInterval: 30,
  appName: 'inclusively-frontend',
  environment: UNLEASH_ENV,
};

const App = () => {
  const classes = useStyles();

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <FlagProvider config={unleashedConfig}>
        <ReactQueryConfigProvider config={reactQueryConfig}>
          <LocaleContextProvider>
            <LoginDetailsContextProvider>
              <AdvocateCandidateContextProvider>
                <EmployerContextProvider>
                  <ThemeProvider theme={AppTheme}>
                    <HubspotProvider>
                      <CssBaseline />
                      <div className={classes.root}>
                        <AppRouter />
                      </div>
                    </HubspotProvider>
                  </ThemeProvider>
                </EmployerContextProvider>
              </AdvocateCandidateContextProvider>
            </LoginDetailsContextProvider>
          </LocaleContextProvider>
        </ReactQueryConfigProvider>
      </FlagProvider>
    </>
  );
};

export default App;
