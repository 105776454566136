import { Button, Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useRef } from 'react';

import { ReactComponent as Icon1 } from '../../assets/svg-icons/accommodations/AlternativeInput.svg';
import { ReactComponent as Icon5 } from '../../assets/svg-icons/accommodations/BehavioralSupport.svg';
import { ReactComponent as Icon4 } from '../../assets/svg-icons/accommodations/InPersonOrLiveAssistance.svg';
import { ReactComponent as Icon2 } from '../../assets/svg-icons/accommodations/ModifiedBreaks.svg';
import { ReactComponent as Icon3 } from '../../assets/svg-icons/accommodations/ServiceAnimalEmotionalSupportAnimal.svg';
import { ReactComponent as TooltipIcon } from '../../assets/svg-icons/tooltip.svg';
import useStyles from './SuccessEnablerTooltip.styles';

const SuccessEnablerTooltip = ({ onChange, isLoading, className }) => {
  const classes = useStyles();
  const tooltipRef = useRef();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    tooltipRef.current.focus();
  };

  return (
    <div>
      <IconButton
        ref={tooltipRef}
        aria-label='Help: what are success enablers?'
        aria-controls={open ? 'success-enablers-popup' : null}
        aria-haspopup='dialog'
        onClick={handleOpen}
        className={`${classes.tooltip} ${className ? className : ''}`}
      >
        {React.createElement(TooltipIcon)}
      </IconButton>
      <Dialog
        aria-labelledby='success-enablers'
        aria-describedby='success-enablers-description'
        className={classes.modal}
        open={open}
        PaperProps={{ id: 'success-enablers-popup', 'aria-modal': 'true' }}
      >
        <div className={classes.paper}>
          <IconButton aria-label='close popup' onClick={handleClose} className={classes.close}>
            <CloseIcon />
          </IconButton>
          <Typography id='success-enablers' variant='h4' component='h1' align='center'>
            What are Success Enablers?
          </Typography>
          <Typography
            id='success-enablers-description'
            variant='body1'
            component='p'
            className={classes.modalBody}
          >
            Success Enablers (workplace accommodations) allow you to filter jobs with your specific
            needs in mind, giving you the ability to search and interview with confidence. When
            selecting your Success Enablers, please only select accommodations that you would
            request from an employer as necessary to do your job.
          </Typography>
          <div className={classes.icons}>
            {React.createElement(Icon1)}
            {React.createElement(Icon2)}
            {React.createElement(Icon3)}
            {React.createElement(Icon4)}
            {React.createElement(Icon5)}
          </div>
          <Button
            aria-label='got it, close popup'
            type='button'
            variant='contained'
            color='secondary'
            fullWidth
            className={classes.primaryButton}
            onClick={handleClose}
          >
            Got it!
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default SuccessEnablerTooltip;
