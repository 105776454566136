import React, { useState } from 'react';

export const KEY_SELECTED_EMPLOYER = 'SELECTED_EMPLOYER';

const getInitialEmployer = () => {
  const employerData = localStorage.getItem(KEY_SELECTED_EMPLOYER);
  const employer = employerData ? JSON.parse(employerData) : null;
  return employer;
};

export const EmployerContext = React.createContext({
  employer: null,
  setEmployer: (employer) => {},
});

export const EmployerContextProvider = ({ children }) => {
  const [employer, setEmployer] = useState(getInitialEmployer);

  const setEmployerWithStorage = (employer) => {
    console.log('SET EMPLOYER CONTEXT: ', employer);
    localStorage.setItem(KEY_SELECTED_EMPLOYER, JSON.stringify(employer));
    setEmployer(employer);
  };

  /**
   * Checks whether the user has selected "Remote Work" as one of his/her accommodations
   * @param {string} objAccom - The accommodations object from the user API
   * @returns {boolean}
   */

  const hasRemoteWork = (objAccom) => {
    let remoteWork = objAccom.find((o) => o.name === 'Remote Work');
    return remoteWork;
  };

  return (
    <EmployerContext.Provider
      value={{ employer, setEmployer: setEmployerWithStorage, hasRemoteWork: hasRemoteWork }}
    >
      {children}
    </EmployerContext.Provider>
  );
};
